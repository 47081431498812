<template>
  <v-navigation-drawer
    width="450"
    order="0"
    location="end"
    elevation="0"
    disable-resize-watcher
    :model-value="isPanelOpen && !!content"
    :temporary="$vuetify.display.mdAndDown"
  >
    <v-card v-if="content" class="d-flex flex-column fill-height px-4">
      <v-card-title class="d-flex flex-row shrink mb-2">
        <span class="headline">{{ action }} content</span>

        <v-spacer />

        <v-btn icon="mdi-close" color="primary" class="mt-n2 mr-n4" @click="close()" />
      </v-card-title>

      <v-card-text ref="scrollRef" class="pr-4 mr-n4 fill-height" style="overflow: auto">
        <v-alert v-if="content?.upload" text="Upload still in progress..." variant="text" type="info" />

        <div class="d-flex align-start">
          <div class="flex-grow-1">
            <v-text-field
              v-model="content.id"
              label="ID / Type"
              readonly
              :error-messages="
                action === 'Create' && contents.find((c) => c.id === (content && content.id)) ? 'ID already exists' : ''
              "
            />
          </div>
          <v-btn class="mt-1 ml-3" color="grey-darken-1" :icon="icons[content.type]" />
        </div>

        <div class="d-flex align-start">
          <div class="flex-grow-1">
            <v-select
              v-model="previewFile"
              label="Variant"
              :items="variants"
              :disabled="loading || content.type === 'slideshow'"
            />
          </div>

          <v-btn
            v-if="isMultiLanguage"
            icon="mdi-plus-thick"
            color="grey-darken-1"
            class="ml-3 mt-1"
            :disabled="loading || (content && !!content.upload) || content.type === 'slideshow'"
            @click="uploadVariant()"
          />
          <v-btn-toggle v-model="translate" class="ml-3 mt-1" variant="plain">
            <v-btn icon="mdi-translate" color="grey-darken-1" :disabled="loading || (content && !!content.upload)" />
          </v-btn-toggle>
        </div>

        <template v-if="isMultiLanguage">
          <Translatable :data="content.title">
            <v-text-field v-model="content.title.text" spellcheck="true" lang="en" label="Title" />
          </Translatable>

          <Translatable v-if="content.type === 'audio' && content.description" :data="content.description">
            <v-textarea v-model="content.description.text" rows="3" spellcheck="true" label="Description" />
          </Translatable>
        </template>
        <template v-else>
          <div :class="{ highlight: translate != null }">
            <div>
              <v-text-field
                v-if="translate == null"
                v-model="content.title.text"
                spellcheck="true"
                lang="en"
                label="Title"
                :disabled="loading"
              />
              <v-text-field
                v-else
                v-model="content.title.localeText"
                spellcheck="true"
                lang="en"
                label="Translated title"
                :disabled="loading"
              />
            </div>

            <div v-if="content.type === 'audio' && content.description">
              <v-textarea
                v-if="translate == null"
                v-model="content.description.text"
                rows="3"
                spellcheck="true"
                label="Description"
                :disabled="loading"
              />
              <v-textarea
                v-else
                v-model="content.description.localeText"
                rows="3"
                spellcheck="true"
                label="Translated description"
                :disabled="loading"
              />
            </div>
          </div>
        </template>

        <div class="v-custom-label"><v-label class="d-flex mb-2">Media file</v-label></div>

        <div v-if="!!content.upload" class="pa-4 text-center">Uploading in progress...</div>

        <div v-else-if="previewFile" class="d-flex flex-column align-center my-2" style="width: 100%">
          <MediaFilePreview :media-url="previewFile.url" :media-type="previewFile.content_type" />

          <div class="d-flex flex-row justify-center mb-2">
            <v-btn
              v-if="variantState && variantState !== 'active'"
              color="primary"
              text="Activate this version"
              size="small"
              :disabled="isUploading"
              @click="activateVariant()"
            />

            <v-btn
              v-if="!previewFile.content_type.startsWith('image')"
              color="primary"
              text="Upload new version"
              size="small"
              @click="uploadVariant()"
            />
          </div>
        </div>

        <div v-else-if="previewInsight" class="my-4" style="max-height: 300px; overflow: auto">
          <ViewFacetPreview :message="previewInsight" style="margin: 0 auto; max-width: 300px" />
        </div>

        <div v-else class="text-center pa-4">
          <p v-if="loading">Loading media file...</p>
          <p v-else>No valid variants available!</p>
        </div>

        <div class="d-flex flex-column cover-image mb-4">
          <div class="v-custom-label"><v-label>Cover image</v-label></div>

          <div
            v-if="content.cover"
            class="ma-4 flex-grow-0 flex-shrink-1 align-self-center text-center"
            style="min-height: 64px; min-width: 300px; max-width: 300px; position: relative"
            @click="openSelectCoverDialog()"
          >
            <p v-if="loading">Loading cover image...</p>
            <p v-else-if="!!content.upload">Uploading in progress...</p>
            <div v-else class="grey-lighten-3">
              <v-img :src="coverUrl" contain style="max-height: 300px; cursor: pointer" />

              <div
                v-if="!!thumbUrl"
                style="
                  position: absolute;
                  bottom: 12px;
                  right: 12px;
                  border: 1px solid rgba(100, 100, 100, 0.9);
                  border-radius: 8px;
                  min-width: 40px;
                  min-height: 40px;
                  background-color: white;
                  z-index: 1;
                  overflow: hidden;
                "
              >
                <v-img :src="thumbUrl" contain style="min-width: 40px; max-width: 40px; max-height: 40px" />
              </div>
            </div>
          </div>

          <div v-else class="align-self-center pa-6">
            <v-btn
              color="primary"
              text="Select cover image"
              size="small"
              :disabled="content && !!content.upload"
              @click="openSelectCoverDialog()"
            />
          </div>
        </div>

        <v-select
          v-model="content.project"
          label="Project *"
          item-title="name"
          item-value="id"
          :items="projects"
          :disabled="!!project"
        />

        <v-autocomplete
          v-model="content.vendor"
          hide-selected
          label="Vendor"
          :items="vendors"
          :readonly="!!content.vendor"
        />

        <v-select v-model="content.category" label="Category" :items="categories" :readonly="!!content.category" />

        <div>
          <div class="v-custom-label"><v-label>Tags</v-label></div>

          <v-chip
            v-for="tag of content.tags"
            :key="tag"
            :close="editTags"
            variant="outlined"
            :closable="editTags"
            color="primary"
            class="mr-2 my-2"
            @mousedown="removeTag(tag)"
          >
            {{ tag }}
          </v-chip>

          <form @submit.prevent="addTag()">
            <div class="d-flex flex-column align-end">
              <v-btn
                v-if="!editTags"
                v-model="editTags"
                color="grey-darken-1"
                icon="mdi-plus-thick"
                @click="editTags = true"
              />
            </div>
            <div v-if="editTags" class="d-flex space-between align-baseline mt-6">
              <v-combobox
                v-if="addTag"
                :v-model="content.tags"
                variant="underlined"
                label="New tag name"
                multiple
                autofocus
                hide-selected
                class="mr-2 pt-0"
                :items="tags"
                @blur="editTags = false"
                @keydown.enter="addTag()"
                @update:search="updateSearchTag($event)"
                @update:model-value="addNewTags($event)"
              >
                <template #selection />
              </v-combobox>

              <v-btn size="small" text="Add" color="primary" :disabled="!tagValid" @click.stop="addTag()" />
            </div>
          </form>
        </div>

        <div class="bottom-anchor" />
      </v-card-text>

      <v-card-actions class="d-flex flex-column align-center justify-space-between">
        <v-alert
          v-if="hasChanges && !content.project"
          text="Project not set, changes can not be saved"
          variant="text"
          colored-border
          type="error"
          class="my-4 px-2"
        />

        <v-btn
          v-else
          class="my-4"
          color="primary"
          text="Save"
          :loading="loading || saving || !!content?.upload"
          :disabled="!hasChanges || (action === 'Create' && !!contents.find((c) => c.id === (content && content.id)))"
          @click="updateContent($event)"
        />
      </v-card-actions>
    </v-card>

    <CommitDialog ref="commitDialogRef" @saved="close()" />

    <SelectMediaDialog ref="selectMediaDialogRef" :current="content?.cover" @confirm="selectConfirmed($event)" />
  </v-navigation-drawer>
</template>

<script lang="ts">
  import { isEqual, uniq, upperFirst } from 'lodash-es'

  import { Component, Emit, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { collection, doc, getDocs, getFirestore, orderBy, query, serverTimestamp, setDoc } from 'firebase/firestore'

  import { contentCategories, contentIcons } from '#views/contents/constants'

  import { createDefaultTranslatable } from '#views/messages/utilities'

  import { AppStore, MediaStore, ProjectsStore, UploadsStore } from '#stores'

  import { Content, Dialog } from '#types'

  @Component({})
  class EditContentPanel extends Vue {
    @Prop() public tags!: string[]
    @Prop() public vendors!: string[]
    @Prop() public contents!: Content[]
    @Prop() public contentsPath!: string

    public newTag = ''
    public coverUrl = ''
    public thumbUrl = ''
    public variantState = ''

    public action = 'Edit'

    public saving = false
    public loading = false

    public editTags = false

    public translate = null

    public variants: any[] = []

    public previewFile: any = null
    public previewInsight: any = null

    public icons = contentIcons
    public categories = contentCategories

    public content: Content | null = null

    private appStore = new AppStore()
    private mediaStore = new MediaStore()
    private uploadsStore = new UploadsStore()
    private projectsStore = new ProjectsStore()

    private interval: number | null = null

    private original: Content | null = null

    @Ref() private readonly scrollRef: HTMLElement | null = null

    @Ref() private readonly commitDialogRef: Dialog | null = null
    @Ref() private readonly selectMediaDialogRef: Dialog | null = null

    @Ref() private readonly audioPlayerRef: HTMLAudioElement | null = null
    @Ref() private readonly videoPlayerRef: HTMLVideoElement | null = null

    public get project() {
      return this.projectsStore.project
    }

    public get projects() {
      return this.projectsStore.projects.filter((p) => p.sections.content || p.id === this.content?.project)
    }

    public get tagValid() {
      const tag = this.newTag && this.newTag.toLowerCase().replace(/ /g, '_')

      return !!this.newTag && !this.content!.tags.includes(tag)
    }

    public get hasChanges() {
      return !isEqual(this.original, this.content)
    }

    public get isUploading() {
      return this.uploadsStore.uploading
    }

    public get isPanelOpen() {
      return this.appStore.navDrawer === 'content'
    }

    public get isMultiLanguage() {
      return (
        this.content!.type === 'slideshow' ||
        !!this.variants.find((v) => v.state !== 'disabled' && (!v.text || v.text === 'en'))
      )
    }

    @Watch('editTags')
    protected onEditTagsChanged(editTags: boolean) {
      if (editTags) {
        setTimeout(() => {
          if (this.scrollRef) {
            this.scrollRef.scrollTop = this.scrollRef.scrollHeight
          }
        }, 10)
      }
    }

    @Watch('previewFile')
    protected onPreviewFileChanged() {
      window.setTimeout(() => {
        if (this.content!.type === 'audio') {
          this.audioPlayerRef?.load()
        } else if (this.content!.type === 'video') {
          this.videoPlayerRef?.load()
        }
      }, 10)
    }

    public beforeUnmount() {
      this.resetPanel()
    }

    public async open(action: string, content: Content) {
      this.original = content

      this.variants = []

      this.newTag = ''
      this.coverUrl = ''
      this.thumbUrl = ''

      this.loading = true

      this.editTags = false

      this.previewFile = null
      this.previewInsight = null

      if (this.interval) {
        window.clearInterval(this.interval!)

        this.interval = null
      }

      this.action = upperFirst(action || 'edit')

      this.content = JSON.parse(JSON.stringify(content))

      this.content!.createdAt = content.createdAt
      this.content!.updatedAt = content.updatedAt

      if (content.publishedAt) {
        this.content!.publishedAt = content.publishedAt
      }

      if (typeof content.description === 'string') {
        this.content!.description = createDefaultTranslatable(
          `${this.content!.id}:explore`,
          `${this.content!.type} entry description`,
        )
      }

      this.appStore.openNavDrawer('content')

      await this.refreshContentCoverInfo()

      if (this.content!.type !== 'slideshow') {
        if (this.content!.upload) {
          this.refreshContentUploadStatus()
        } else {
          await this.refreshContentMediaInfo()
        }
      } else {
        const messages = await getDocs(
          query(collection(getFirestore(), `slideshows/${this.content!.media}/messages`), orderBy('order')),
        )

        delete this.content!.description

        this.previewInsight = messages.docs[0].data()

        this.content!.duration = messages.docs.length * 15

        this.variants = [{ title: 'All slideshow languages', value: null }]

        this.content!.languages = ['en', 'da', 'de', 'es', 'fi', 'fr', 'it', 'ja', 'nb', 'sv']
      }

      setTimeout(() => {
        if (this.scrollRef) {
          this.scrollRef.scrollTop = 0
        }
      }, 0)

      this.loading = false
    }

    @Emit('close')
    public close() {
      this.resetPanel()

      this.appStore.closeNavDrawer()
    }

    public updateSearchTag(tag: string) {
      this.newTag = tag
    }

    public addTag() {
      const tag = this.newTag.toLowerCase().replace(/ /g, '_')
      if (!this.content!.tags.includes(tag)) {
        this.content!.tags = [...this.content!.tags, tag]
      }

      setTimeout(() => {
        this.newTag = ''

        this.editTags = true

        if (this.scrollRef) {
          this.scrollRef.scrollTop = this.scrollRef.scrollHeight
        }
      }, 10)
    }

    public addNewTags(tags: readonly string[]) {
      this.content!.tags = uniq([...this.content!.tags, ...tags])
    }

    public removeTag(tag: string) {
      if (this.editTags) {
        this.content!.tags.splice(this.content!.tags.indexOf(tag), 1)
        this.content!.tags = [...this.content!.tags]
      }

      setTimeout(() => {
        this.editTags = true

        if (this.scrollRef) {
          this.scrollRef.scrollTop = this.scrollRef.scrollHeight
        }
      }, 10)
    }

    public async updateContent(event: any) {
      if (event.ctrlKey) {
        this.commitDialogRef!.open(
          this.content!.title.text,
          `${this.contentsPath}/${this.content!.id}`,
          this.content,
          this.original,
        )
      } else {
        this.saving = true

        await setDoc(doc(getFirestore(), `${this.contentsPath}/${this.content!.id}`), {
          ...this.content,
          author: this.appStore.author,
          updatedAt: serverTimestamp(),
          createdAt: this.content?.createdAt ? this.content.createdAt : serverTimestamp(),
        })

        this.saving = false

        this.close()
      }
    }

    public uploadVariant() {
      this.selectMediaDialogRef!.open(this.content!.type, {
        slug: this.content!.media,
        locales: this.variants.filter((v) => v.state !== 'disabled').map((v) => v.text),
      })
    }

    public async activateVariant() {
      await this.uploadsStore.updateVariants({
        slug: this.content!.media,
        type: this.content!.type,
      })

      this.refreshContentMediaInfo()
    }

    public selectConfirmed(media: any) {
      if (media.media_type === 'image') {
        if (this.content?.cover) {
          this.content!.cover_revision = (this.content!.cover_revision || 0) + 1
        }

        this.content!.cover = media.slug

        this.refreshContentCoverInfo()
      }

      if (media.upload_job_id) {
        this.content!.upload = media.upload_job_id

        this.refreshContentUploadStatus()
      }
    }

    public openSelectCoverDialog() {
      if (!this.loading && !this.content?.upload) {
        this.selectMediaDialogRef!.open('image', {
          provider: this.content!.vendor,
          category: this.content!.category,
        })
      }
    }

    private resetPanel() {
      if (this.content?.type === 'audio' && this.audioPlayerRef) {
        this.audioPlayerRef!.load()
      } else if (this.content?.type === 'video' && this.videoPlayerRef) {
        this.videoPlayerRef!.load()
      }

      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = null
      }
    }

    private async refreshContentCoverInfo() {
      const coverPreviewresponse = await this.mediaStore.getMediaInfo({
        slug: this.content!.cover,
        type: 'image',
      })

      const foundGradient = coverPreviewresponse.data?.info?.info?.gradient

      if (foundGradient) {
        this.content!.placeholder = {
          values: foundGradient,
          type: 'gradient',
        }
      }

      const coverVariants = (coverPreviewresponse?.data?.info?.variants || []).reverse()

      const coverIndex = coverVariants.findIndex((v: any) => v.state !== 'disabled' && v.info.rendition === 'original')
      const thumbIndex = coverVariants.findIndex((v: any) => v.state !== 'disabled' && v.info.rendition === 'thumbnail')

      this.coverUrl = coverVariants[coverIndex === -1 ? 0 : coverIndex]?.media_file?.url || ''

      this.thumbUrl = thumbIndex === -1 ? '' : coverVariants[thumbIndex]?.media_file?.url || ''
    }

    private async refreshContentMediaInfo() {
      const mediaPreviewResponse = await this.mediaStore.getMediaInfo({
        refresh: true,
        slug: this.content!.media,
        type: this.content!.type,
      })

      const mediaVariants = (mediaPreviewResponse?.data?.info?.variants || []).filter(
        (v: any) => v.state !== 'disabled',
      )

      this.variants = mediaVariants.map((v: any) => ({
        title: v.info.content_language,
        value: v.media_file,
      }))

      // TODO: should perhaps check the timestamps to get the latest file,
      //       same logic should be in cloud function that activates files!

      this.variantState = mediaVariants.at(-1)?.state || ''

      this.previewFile = mediaVariants.at(-1)?.media_file || null

      this.content!.duration = mediaVariants.at(-1)?.info?.duration || 0

      this.content!.languages = mediaVariants
        .filter((v: any) => v.state !== 'disabled')
        .map((v: any) => v.info?.content_language || 'en')

      if (this.content!.type === 'video') {
        delete this.content!.description
      }
    }

    private refreshContentUploadStatus() {
      if (!this.interval && this.content!.upload) {
        this.interval = window.setInterval(async () => {
          const response: any = await this.uploadsStore.getJobStatus({ jobId: this.content!.upload })

          if (response.data?.jobStatus !== 'running') {
            this.content!.upload = ''

            this.refreshContentCoverInfo()

            if (this.content!.type !== 'slideshow') {
              this.refreshContentMediaInfo()
            }

            window.clearInterval(this.interval!)

            this.interval = null
          }
        }, 10 * 1000)
      }
    }
  }

  export default toNative(EditContentPanel)
</script>

<style lang="scss" scoped>
  .highlight {
    padding: 6px 6px 0 6px;
    margin: -6px -6px 0 -6px;
    border-radius: 6px;
    background-color: #e3e3e3;
  }

  .v-custom-label label {
    font-size: 13px;
  }

  :deep(.cover-image) {
    .v-lazy + div {
      display: none;
    }
  }
</style>
