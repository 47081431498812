<template>
  <v-row dense class="d-flex align-center justify-space-between flex-wrap">
    <v-col class="d-flex align-center justify-space-between flex-no-wrap">
      <p>
        In Play Store review. Once review has passed, please 'mark as released' manually in Waltari.
        <br />
        Play Store does not provide status of the review process.
      </p>

      <v-spacer />

      <v-tooltip location="bottom">
        <template #activator="{ props }">
          <v-chip v-bind="props" link variant="text" target="_blank" rel="noopener noreferrer" :href="appStoreLink">
            <v-icon icon="mdi-open-in-new" />
          </v-chip>
        </template>

        <span>Open Play Store</span>
      </v-tooltip>
    </v-col>
  </v-row>

  <v-btn
    rounded="1"
    variant="flat"
    class="mt-2"
    color="success"
    text="Mark as released"
    prepend-icon="mdi-check-circle"
    @click="updateManagedPublishingStatus()"
  />
</template>

<script lang="ts">
  import { storeUrls } from './constants'

  import { Component, Prop, Vue, toNative } from 'vue-facing-decorator'

  import { ReleasesStore } from '#stores/releases'

  import { AppBuild, ManagedPublishingStatus } from '#types'

  @Component({})
  class UpdateManagedPublishingStatus extends Vue {
    @Prop({ required: true }) public selectedBuild!: AppBuild

    private readonly releasesStore = new ReleasesStore()

    public get isInManagedPublishingReview() {
      return this.selectedBuild.managedPublishingStatus === ManagedPublishingStatus.SUBMITTED
    }

    public get appStoreLink() {
      return storeUrls[this.selectedBuild.platform]
    }

    public updateManagedPublishingStatus() {
      const { buildVersion, platform, createdAt } = this.selectedBuild

      this.releasesStore.updateBuildData(platform, buildVersion, {
        createdAt,
        managedPublishingStatus: ManagedPublishingStatus.RELEASED,
      })
    }
  }

  export default toNative(UpdateManagedPublishingStatus)
</script>
