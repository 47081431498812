<template>
  <v-alert
    :color="
      overrideRolloutStatus(disableOverride) !== 'INACTIVE'
        ? 'error'
        : hasAdvancedRollouts(feature)
          ? 'info'
          : 'grey-darken-2'
    "
    class="flex-shrink-1 flex-grow-0 my-8 py-4 px-6"
  >
    <div class="text-h5 font-weight-light">
      <template v-if="overrideRolloutStatus(disableOverride) !== 'INACTIVE'">
        Feature is fully disabled, but some rollouts can be active
      </template>
      <template v-else-if="hasAdvancedRollouts(feature)">
        This feature has additional advanced rollouts that are active
      </template>
      <template v-else>This feature does not have any currently active custom rollouts</template>
    </div>

    <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
      <template v-if="isFeatureAdmin">
        Your feature admin rights allow you to create and manage rollouts in projects you have access to
      </template>
      <template v-else>
        You can only view the existing advanced rollouts since you do not have the feature admin rights
      </template>
    </div>

    <template #append>
      <v-icon v-if="overrideRolloutStatus(disableOverride) !== 'INACTIVE'" class="text-error">mdi-alert-outline</v-icon>

      <v-icon v-else-if="hasAdvancedRollouts(feature)" class="text-info">mdi-information-outline</v-icon>

      <v-icon v-else class="text-grey">mdi-information-outline</v-icon>
    </template>
  </v-alert>

  <v-sheet>
    <v-data-table
      show-expand
      expand-on-click
      hide-default-footer
      no-data-text="No rollouts or experiments configured"
      item-value="uid"
      :loading="isLoading"
      :expanded="expandedRow"
      :headers="rolloutsHeaders"
      :items="featureRollouts"
      :items-per-page="1000"
      @update:expanded="selectRollout($event)"
    >
      <template #header.priority="{ column }">
        <div class="text-no-wrap">
          <span class="text-primary">{{ column.title }}</span>

          <v-tooltip location="start">
            <template #activator="{ props }">
              <v-icon v-bind="props" color="primary" class="ml-2 mr-4">mdi-help-circle-outline</v-icon>
            </template>

            When multiple rollouts match the same user,
            <br />
            the one highest in this list will get applied.
          </v-tooltip>
        </div>
      </template>

      <template #item.name="{ item }">
        {{ (item.oneOf as any)[item.oneOf!.$case].metadata?.uid?.slice(0, -7)?.toUpperCase() || '' }}
      </template>

      <template #item.type="{ item }">
        <span class="text-capitalize">
          {{ getOverrideType(item) }}
        </span>
      </template>

      <template #item.features="{ item }">
        {{ (linkedFeatures(item).length || 'No') + ' other feature flags' }}
      </template>

      <template #item.status="{ item }">
        <span :class="'text-' + statusColors[rolloutStatus(item)]">
          {{ rolloutStatus(item) }}
        </span>
      </template>

      <template #item.priority="{ item, index }">
        <div
          v-if="!orderedOverride || orderedOverride?.uid !== item?.uid"
          class="d-flex flex-row align-center justify-end"
        >
          <span class="pl-8" @click.stop>
            {{ index + 1 }}
          </span>

          <span @click.stop>
            <v-tooltip text="Change priority" location="start">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="mdi-playlist-edit"
                  :disabled="!!orderedOverride"
                  @click.stop="orderedOverride = item"
                />
              </template>
            </v-tooltip>
          </span>
        </div>
        <div v-else class="d-flex flex-row justify-end">
          <span @click.stop>
            <v-tooltip text="Higher" location="start">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="mdi-arrow-up-thick"
                  :disabled="
                    index === 0 ||
                    !isProjectEditor(feature) ||
                    (overrideRolloutStatus(disableOverride) !== 'INACTIVE' && index === 1)
                  "
                  @click.stop="changeOrder(index, index - 1)"
                />
              </template>
            </v-tooltip>
          </span>

          <span @click.stop>
            <v-tooltip text="Lower" location="start">
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon="mdi-arrow-down-thick"
                  :disabled="
                    index === featureRollouts.length - 1 ||
                    !isProjectEditor(feature) ||
                    (overrideRolloutStatus(disableOverride) !== 'INACTIVE' && index < 1)
                  "
                  @click.stop="changeOrder(index, index + 1)"
                />
              </template>
            </v-tooltip>
          </span>
        </div>
      </template>

      <template #expanded-row="{ item }">
        <RolloutDrawer :feature="feature" :features="linkedFeatures(item)" :overrides="listFeatureOverrides(item)" />
      </template>
    </v-data-table>
  </v-sheet>

  <v-footer
    v-if="isFeatureAdmin"
    order="1"
    class="d-flex flex-row flex-shrink-1 py-4"
    style="border-top: 1px solid rgba(0, 0, 0, 0.2)"
  >
    <template v-if="!orderedOverride">
      <template v-if="isProjectEditor(feature)">
        <v-btn
          v-if="overrideRolloutStatus(disableOverride) === 'INACTIVE'"
          text="Disable everywhere"
          :disabled="isLoading"
          color="error"
          prepend-icon="mdi-cancel"
          @click="disableRollouts()"
        />
        <v-btn
          v-else
          :text="`Enable everywhere`"
          :disabled="isLoading"
          color="success"
          prepend-icon="mdi-check-outline"
          @click="enableRollouts()"
        />
      </template>

      <v-spacer />

      <v-btn
        text="Create custom rollout"
        color="primary"
        prepend-icon="mdi-rocket-outline"
        :disabled="isLoading"
        @click="emitCreate()"
      />
    </template>
    <template v-else>
      <v-spacer />

      <v-btn
        text="Cancel priority change"
        variant="text"
        prepend-icon="mdi-cancel"
        :disabled="isLoading"
        @click="((orderedOverride = undefined), (orderedOverrides = undefined))"
      />

      <v-btn
        text="Save priority change"
        variant="text"
        color="success"
        prepend-icon="mdi-check-outline"
        :disabled="
          isLoading ||
          !orderedOverrides ||
          orderedOverrides?.indexOf(orderedOverride) ===
            feature.overrideList.length - feature.overrideList.indexOf(orderedOverride) - 1
        "
        @click="saveOrder()"
      />
    </template>
  </v-footer>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { rolloutTypes, rolloutsHeaders, statusColors } from '#views/features/constants'

  import {
    createOverride,
    createRolloutStage,
    findFeatureOverride,
    forEachFeatureOverride,
    hasAdvancedRollouts,
    overrideRolloutStatus,
  } from '#views/features/utilities'
  import { getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { AppStore, FeaturesStore } from '#stores'

  import { Feature, OverrideEntry } from '#types'

  import { nanoId } from '#utilities'

  @Component({})
  class RolloutsListing extends Vue {
    @Prop() public feature!: Feature

    @Prop() public rolloutId!: string

    public expandedRow: string[] = []

    public orderedOverride?: OverrideEntry
    public orderedOverrides?: OverrideEntry[]

    public readonly statusColors = statusColors
    public readonly rolloutTypes = rolloutTypes

    public readonly rolloutsHeaders = rolloutsHeaders

    public readonly isProjectEditor = isProjectEditor
    public readonly hasAdvancedRollouts = hasAdvancedRollouts
    public readonly overrideRolloutStatus = overrideRolloutStatus

    private readonly appStore = new AppStore()

    private readonly featuresStore = new FeaturesStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get allFeatures() {
      return this.featuresStore.features
    }

    public get isWaltariAdmin() {
      return this.appStore.isWaltariAdmin
    }

    public get isFeatureAdmin() {
      return this.appStore.isAppFeatureAdmin
    }

    public get disableOverride() {
      return findFeatureOverride(this.feature, 'disable')
    }

    public get featureRollouts() {
      return (
        this.orderedOverrides ||
        [...this.feature.overrideList].reverse().map((o) => ({
          ...o,
          uid: (o.oneOf as any)[o.oneOf!.$case].metadata.uid,
        }))
      )
    }

    public get selectRolloutRootPath() {
      return `/features/${getProjectKey(this.feature)}/${this.feature.metadata?.name}/rollouts/advanced`
    }

    @Emit('create')
    public emitCreate() {
      return null
    }

    @Watch('rolloutId', { immediate: true })
    protected rolloutIdChanged() {
      if (this.rolloutId) {
        this.expandedRow = [this.rolloutId]
      }
    }

    public async saveOrder() {
      const index = this.orderedOverrides!.indexOf(this.orderedOverride!)

      await this.featuresStore.moveOverride(this.feature, this.orderedOverride!, this.orderedOverrides![index + 1]?.uid)

      this.orderedOverride = undefined
      this.orderedOverrides = undefined
    }

    public changeOrder(from: number, to: number) {
      if (!this.orderedOverrides) {
        this.orderedOverrides = [...this.featureRollouts]
      }

      this.orderedOverrides.splice(to, 0, this.orderedOverrides.splice(from, 1)[0])
    }

    public rolloutStatus(rollout: OverrideEntry) {
      const statuses: string[] = []

      const overrides =
        rollout?.oneOf?.$case === 'override' ? [rollout?.oneOf?.override] : rollout?.oneOf?.group?.overrides

      if (overrides) {
        for (const o of overrides) {
          statuses.push(overrideRolloutStatus(o))
        }

        return statuses.every((s) => s === statuses[0]) ? statuses[0] : 'IN PROGRESS'
      }

      return 'INACTIVE'
    }

    public selectRollout(rollout: string[]) {
      this.expandedRow = rollout.splice(-1)

      this.$router.replace(
        this.expandedRow[0] ? `${this.selectRolloutRootPath}/${this.expandedRow[0]}` : this.selectRolloutRootPath,
      )
    }

    public linkedFeatures(rollout: OverrideEntry) {
      const features: string[] = []

      const firstOverride =
        rollout?.oneOf?.$case === 'override' ? rollout?.oneOf?.override : rollout?.oneOf?.group?.overrides[0]

      const rangePredicate =
        firstOverride?.criteria?.oneOf?.$case === 'and' &&
        firstOverride.criteria.oneOf.and.expressions.find(
          (e) =>
            e.oneOf?.$case === 'predicate' &&
            e.oneOf?.predicate.oneOf?.$case === 'user' &&
            e.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range',
        )

      const sortingKeyName =
        (firstOverride?.rolloutOneOf?.$case === 'rollout' &&
          firstOverride.rolloutOneOf.rollout.userSortingKeyRef &&
          firstOverride.rolloutOneOf.rollout.userSortingKeyRef.name) ||
        (rangePredicate &&
          rangePredicate?.oneOf?.$case === 'predicate' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.$case === 'user' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'range' &&
          rangePredicate?.oneOf?.predicate?.oneOf?.user?.oneOf?.range.userSortingKey?.name)

      if (sortingKeyName) {
        for (const f of this.allFeatures) {
          if (f.metadata?.name !== this.feature.metadata?.name || getProjectKey(f) !== getProjectKey(this.feature)) {
            forEachFeatureOverride(f, (o) => {
              if (
                (o.rolloutOneOf?.$case === 'rollout' &&
                  o.rolloutOneOf.rollout.userSortingKeyRef &&
                  o.rolloutOneOf.rollout.userSortingKeyRef.name === sortingKeyName) ||
                (o.rolloutOneOf?.$case === 'namedRolloutRef' &&
                  o.rolloutOneOf.namedRolloutRef.name === sortingKeyName) ||
                (o.criteria?.oneOf?.$case === 'and' &&
                  o.criteria.oneOf.and.expressions.find(
                    (e) =>
                      (e.oneOf?.$case === 'predicate' &&
                        e.oneOf?.predicate.oneOf?.$case === 'user' &&
                        e.oneOf?.predicate.oneOf?.user.oneOf?.$case === 'range' &&
                        e.oneOf.predicate.oneOf.user.oneOf.range.userSortingKey?.name) === sortingKeyName,
                  ))
              ) {
                if (!features.includes(getProjectKey(f) + '/' + f.metadata?.name)) {
                  features.push(getProjectKey(f) + '/' + f.metadata?.name)
                }
              }
            })
          }
        }
      }

      return features
    }

    public enableRollouts() {
      this.$confirm(
        `Revert disabling of the feature?`,
        'This will remove the emergency feature disabling override and restore normal feature status.',
        { buttonTrueColor: 'error' },
      ).then(async (confirmed) => {
        if (confirmed) {
          const override = findFeatureOverride(this.feature, 'disable')

          this.featuresStore.deleteOverrides(this.feature, [override!])
        }
      })
    }

    public disableRollouts() {
      this.$confirm(
        `Disable feature completely everywhere?`,
        'This should be used as last resort action and will disable the feature for ALL environments.',
        { buttonTrueColor: 'error' },
      ).then(async (confirmed) => {
        if (confirmed) {
          const override = this.createDisableRollout()

          override.metadata!.informative!.additionalData!.launchAt = this.$dayjs().toISOString()

          await this.featuresStore.updateOverrides(this.feature, [override])

          await this.featuresStore.launchOverridesRollout(this.feature, [override])
        }
      })
    }

    public getOverrideType(item: OverrideEntry) {
      let template = ''

      if (item.oneOf?.$case === 'group') {
        template = item.oneOf?.group?.metadata?.uid?.startsWith('all_default_feature_rollouts')
          ? 'Percentage - All environment'
          : item.oneOf?.group?.overrides[0].metadata?.informative?.labels.template || ''
      } else if (item.oneOf?.$case === 'override') {
        template =
          (item.oneOf?.$case === 'override' && item.oneOf?.override?.metadata?.informative?.labels?.template) || ''
      }

      return rolloutTypes.find((t) => t.value === template)?.title || template
    }

    public listFeatureOverrides(item: OverrideEntry) {
      return item?.oneOf?.$case === 'override' ? [item?.oneOf?.override] : item?.oneOf?.group?.overrides
    }

    private createDisableRollout() {
      console.info('Creating disable rollout')

      const override = createOverride(this.feature)

      override.metadata!.uid = `default_disable_all_rollout_${nanoId()}`

      override.metadata!.informative!.labels.rollout = 'disable'
      override.metadata!.informative!.labels.template = 'percentage-advanced'
      override.metadata!.informative!.description = 'Created by Waltari for disabling the feature.'

      if (override.rolloutOneOf?.$case === 'rollout') {
        override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
      }

      if (override.parameters.enabled.oneOf?.$case === 'boolean') {
        override.parameters.enabled.oneOf.boolean = false
      }

      override.metadata!.informative!.referenceUrls = [
        'https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management',
      ]

      if (override.criteria?.oneOf?.$case === 'and') {
        override.criteria.oneOf.and.expressions.push({
          oneOf: {
            $case: 'predicate',
            predicate: {
              oneOf: {
                $case: 'generic',
                generic: {
                  oneOf: {
                    $case: 'literal',
                    literal: true,
                  },
                },
              },
            },
          },
        })
      }

      return override
    }
  }

  export default toNative(RolloutsListing)
</script>
