<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <template
    v-if="
      criteria?.expression?.oneOf?.$case === 'predicate' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
      criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
    "
  >
    <v-radio-group
      v-if="!criteria?.metadata?.changeRecord?.createdAt"
      v-model="labelCreateMode"
      inline
      class="mb-2"
      @update:model-value="resetLabelName()"
    >
      <v-radio label="Create new label" value="create" class="mr-8" />
      <v-radio label="Use an existing label" value="existing" />
    </v-radio-group>

    <v-text-field
      v-if="labelCreateMode === 'create' || !!criteria?.metadata?.changeRecord?.createdAt"
      label="Segment label ID"
      :readonly="!!criteria.metadata?.changeRecord?.createdAt"
      :model-value="
        createLabel || criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals || null
      "
      :error-messages="
        !!createLabel && validateLabelName(createLabel) !== true
          ? [
              'Must be longer than 10 characters and use only small letters, numbers or :_- characters. No spaces allowed.',
            ]
          : existingLabels.find((l) => l.name === createLabel)
            ? ['Label with this name already exists']
            : []
      "
      @update:model-value="((createLabel = $event), formatLabelName($event))"
    >
      <template #append-inner>
        <v-tooltip location="top">
          <template #activator="{ props }">
            <v-icon
              color="grey"
              v-bind="props"
              icon="mdi-content-copy"
              @click="
                useClipboard.copy(
                  createLabel || criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals,
                )
              "
            />
          </template>
          {{ useClipboard.copied ? 'Copied' : 'Copy to clipboard' }}
        </v-tooltip>
      </template>
    </v-text-field>

    <v-autocomplete
      v-else
      label="Segment label ID"
      placeholder="Select label to connect"
      :loading="isLoading"
      :items="filteredLabels.map((l) => l.name)"
      :error-messages="
        !!createLabel && validateLabelName(createLabel) !== true
          ? ['Needs to be longer than 10 characters and use only small letters, numbers or :_- characters']
          : []
      "
      :disabled="!!criteria.metadata?.changeRecord?.createdAt"
      :model-value="criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals || null"
      @update:model-value="formatLabelName($event)"
    />

    <v-alert
      v-if="
        !isLoading &&
        !existingLabels.find(
          (l) =>
            l.name ===
            (criteria?.expression?.oneOf?.$case === 'predicate' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
              criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals' &&
              criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals),
        ) &&
        validateLabelName(
          (criteria?.expression?.oneOf?.$case === 'predicate' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
            criteria?.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals' &&
            criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) ||
            '',
        ) === true
      "
      color="info"
      class="mt-n2 mb-4"
    >
      Given label does not exist and it will be created when you create this segment. You will be able to manage the
      users after the segment is created.
    </v-alert>

    <template
      v-if="
        criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals &&
        (!createLabel ||
          (createLabel === criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals &&
            labelCreateMode === 'existing')) &&
        validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) === true
      "
    >
      <div class="text-button my-2">Users ({{ pagination?.total || 0 }})</div>

      <v-select
        label="Users environment"
        :items="cloudEnvs"
        :loading="isLoading"
        :model-value="criteria.metadata!.informative!.labels.labelsEnv"
        @update:model-value="
          ((criteria.metadata!.informative!.labels.labelsEnv = $event),
          updateLabelUsers(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals))
        "
      />

      <template v-if="!isLoading">
        <CsvImportDialog :json-schema="labelUsersSchema" @import="importUsersToLabel($event)">
          <template #default>
            <v-btn
              block
              variant="outlined"
              class="my-1"
              color="primary"
              text="Import users from CSV file"
              :disabled="
                validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) !==
                true
              "
            />
          </template>
        </CsvImportDialog>

        <v-alert v-if="userImportError" :text="userImportError" color="error" class="mt-4" />

        <p class="text-center pt-2">OR</p>

        <v-text-field
          v-model.trim="userText"
          v-lowercase
          single-line
          label="Type uuid / email to add"
          prepend-inner-icon="mdi-account"
          hide-details="auto"
          :loading="isLoading || isWaiting"
          :hint="userText.includes('@') ? 'Press enter to search with the email' : ''"
          :rules="[(text: string) => (text && !text.includes('@') ? validateUserUid(text) : userAddInputError || true)]"
          :disabled="
            validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals) !== true
          "
          @keyup.enter="convertEmailToUserUid(true)"
          @update:model-value="userAddInputError = ''"
        />

        <div class="d-flex flex-row">
          <v-spacer />

          <v-btn
            class="mt-2"
            color="primary"
            text="Add"
            :disabled="
              !userText ||
              validateUserUid(userText) !== true ||
              !validateLabelName(criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals)
            "
            @click="appendUsersToLabel([userText])"
          />
        </div>

        <v-tooltip v-if="existingUsers.length > 0" location="top">
          <template #activator="{ props }">
            <v-alert
              v-bind="props"
              dense
              closable
              class="mt-2"
              type="info"
              @click="copyUnAddedUsersToClipboard(existingUsers)"
              @click:close="existingUsers = []"
            >
              User{{ existingUsers.length === 1 ? 's' : '' }} already exist{{ existingUsers.length === 1 ? 's' : '' }}.
              Hover for list, click to copy.
            </v-alert>
          </template>
          <div v-for="(user, i) in existingUsers" :key="i">{{ user }}</div>
        </v-tooltip>

        <v-alert
          v-if="notFoundUsers.length > 0"
          dense
          closable
          type="error"
          class="mt-2"
          @click="copyUnAddedUsersToClipboard(notFoundUsers)"
          @click:close="notFoundUsers = []"
        >
          <v-tooltip location="end">
            <template #activator="{ props }">
              <span v-bind="props">Some added users did not exist. Hover for list, click to copy.</span>
            </template>
            <div v-for="(user, i) in notFoundUsers" :key="i">{{ user }}</div>
          </v-tooltip>
        </v-alert>

        <div class="mt-4 mb-2">
          <div v-if="!hasSelectedUsers" class="text-button">{{ search ? 'Search results' : 'Last added' }}</div>

          <v-toolbar v-else class="mt-n3" color="secondary" density="compact">
            <v-toolbar-title class="text-overline">{{ selectedUsers.length }} selected</v-toolbar-title>

            <v-toolbar-items v-if="hasSelectedUsers">
              <v-btn @click="removeUsersFromLabel()">
                Remove selected

                <template #append>
                  <v-icon>mdi-delete</v-icon>
                </template>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-data-table-server
            v-model="selectedUsers"
            hide-default-footer
            item-value="userUid"
            :search="search"
            :headers="userHeaders"
            :items="labelUsers"
            :items-per-page="10"
            :items-length="pagination?.total || 0"
            :height="labelUsers.length > 100 ? '400px' : 'auto'"
            :no-data-text="search ? 'No users matching uuid' : 'No users saved for the label'"
            @update:options="loadLabelUsers($event)"
          >
            <template #header.actions="{ selectAll, allSelected }">
              <v-checkbox :model-value="allSelected" @update:model-value="selectAll(!allSelected)" />
            </template>

            <template #item.createdAt="{ item }">
              {{ item.createdAt ? $dayjs(item.createdAt).format('DD MMM YYYY') : 'Just now' }}
            </template>

            <template #item.actions="{ internalItem, isSelected, toggleSelect }">
              <v-checkbox
                :model-value="isSelected(internalItem)"
                @click.stop
                @update:model-value="toggleSelect(internalItem)"
              />
            </template>

            <template #top>
              <v-text-field
                v-model.trim="search"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                variant="underlined"
                class="my-3"
                placeholder="Search segment user by email or UUID"
                :error-messages="userSearchInputError"
                @keyup.enter="loadLabelUsers({ page: 1, itemsPerPage: 10, search: search })"
              />
            </template>

            <template #bottom>
              <v-btn
                v-if="!search && pagination?.total > labelUsers.length"
                class="mt-4"
                text="Load more users"
                color="primary"
                @click="
                  loadLabelUsers({
                    page: labelUsers.length !== 100 ? 1 : activePage + 1,
                    itemsPerPage: 100,
                    search: search,
                  })
                "
              />
            </template>
          </v-data-table-server>
        </div>
      </template>

      <v-alert v-if="importing" color="info">
        Importing users this may take a while depending on the size of import...
      </v-alert>
    </template>
  </template>
</template>

<script lang="ts">
  import slug from 'slug'

  import { useClipboard } from '@vueuse/core'

  import { useGoTo } from 'vuetify'

  import { Component, Emit, Prop, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { CsvImportDialog, Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { cloudEnvs, labelUsersSchema, userHeaders } from '#views/segments/constants'

  import { validateUserUid, validateUserUids } from '#views/segments/utilities'

  import { LabelsStore } from '#stores'

  import { Criteria, UserLabel } from '#types'

  @Component({
    components: {
      CsvImportDialog,
    },
  })
  class CriteriaLabel extends Vue {
    @Prop() public criteria!: Criteria

    @Emit('validate')
    public emitValidate() {
      return this.labelValid
    }

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public search = ''
    public userText = ''
    public createLabel = ''

    public userImportError = ''
    public userAddInputError = ''
    public userSearchInputError = ''

    public activePage = 1

    public importing = false
    public labelValid = true

    public labelCreateMode = 'create'

    public selectedUsers: string[] = []
    public notFoundUsers: string[] = []
    public existingUsers: string[] = []

    public readonly cloudEnvs = cloudEnvs
    public readonly userHeaders = userHeaders

    public readonly useClipboard = useClipboard()

    public readonly validateUserUid = validateUserUid
    public readonly validateUserUids = validateUserUids

    private readonly labelsStore = new LabelsStore()

    private readonly cloudEnv = import.meta.env.VITE_CLOUD_ENV.replace('dev', 'test')

    public get isLoading() {
      return this.labelsStore.loading
    }

    public get isWaiting() {
      return this.labelsStore.waiting
    }

    public get env() {
      return this.criteria.metadata?.informative?.labels.labelsEnv ?? this.cloudEnv
    }

    public get labelUsers() {
      return this.labelsStore.users
    }

    public get pagination() {
      return this.labelsStore.pagination
    }

    public get hasSelectedUsers() {
      return this.selectedUsers?.length > 0
    }

    public get existingLabels() {
      return this.labelsStore.labels.sort((a: any, b: any) => a.name.localeCompare(b.name))
    }

    public get filteredLabels() {
      return this.existingLabels.filter((l) => !l.name.startsWith('criteria:') && !l.name.startsWith('research:id:'))
    }

    public get labelUsersSchema() {
      const schema = labelUsersSchema

      const labelUserMap = new Map(this.labelUsers.map((u) => [u.userUid, true]))

      schema.userUid['filters'] = [
        {
          name: 'Filter not found uuids',
          filter: (userUid, _index, _rows) => userUid !== 'NOT FOUND',
        },
        {
          name: 'Filter not valid uuids',
          filter: (userUid, _index, _rows) => validateUserUid(userUid as string) === true,
        },
        {
          name: 'Filter existing uuids',
          filter: (userUid, _index, _rows) => !labelUserMap.get(userUid as string),
        },

        {
          name: 'Filter duplicate uuids',
          filter: (userUid, index, rows) => !(rows.get(userUid) !== index),
        },
      ]

      schema.userUid['converters'] = [
        {
          name: 'Convert emails to uuids',
          convert: async (rows) => {
            const values = rows as string[]

            const emails = values.filter((v) => v.includes('@'))

            if (!emails.length) {
              return rows
            } else if (emails.length > 10000) {
              this.userImportError = 'Maximum of 10,000 emails allowed in one import!'
              return [] as typeof rows
            } else {
              const uuids = await this.labelsStore.convertEmailsToUids(this.env, emails)

              return values.map((v) => (v.includes('@') ? uuids.shift() || 'NOT FOUND' : v)) as typeof rows
            }
          },
        },
      ]

      return schema
    }

    @Watch('criteria', { immediate: true })
    protected criteriaChanged() {
      if (
        this.criteria?.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
      ) {
        if (this.criteria && !this.criteria.metadata?.informative?.labels.labelsEnv) {
          this.criteria.metadata!.informative!.labels.labelsEnv = this.cloudEnv
        }

        let label = this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals

        if (label) {
          this.setLabelName()
        }
      }

      this.search = ''

      this.notFoundUsers = []
      this.existingUsers = []

      this.labelsStore.loadLabels(this.env)
    }

    @Watch('criteria.metadata.name', { immediate: true })
    protected criteriaNameChanged() {
      this.resetLabelName()
    }

    @Watch('labelCreateMode')
    protected labelCreateModeChanged() {
      // Empty the segment label ID field when switching between 'create' and 'existing' label modes

      this.createLabel = ''

      if (
        this.criteria.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
      ) {
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals = this.createLabel
      }

      this.labelsStore.clearLabelUsers()
    }

    public setLabelName() {
      this.createLabel = ''

      this.labelsStore.clearLabelUsers()
    }

    public resetLabelName() {
      if (
        !this.criteria?.metadata?.changeRecord?.createdAt &&
        this.criteria.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
      ) {
        if (this.labelCreateMode === 'create' && this.criteria?.metadata?.name) {
          let label = this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals

          if (!label || this.createLabel) {
            this.createLabel = slug(this.criteria.metadata.name, '_')

            this.formatLabelName(this.createLabel)
          }
        } else {
          this.setLabelName()

          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals = this.createLabel
        }
      }
    }

    public formatLabelName(label: string) {
      if (label !== null) {
        label = label || this.createLabel || ''

        if (
          label &&
          !label.startsWith('criteria:') &&
          this.validateLabelName(label) === true &&
          !this.existingLabels.find((l: UserLabel) => l.name === label)
        ) {
          label = 'criteria:' + label
        }

        if (
          this.criteria.expression?.oneOf?.$case === 'predicate' &&
          this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
        ) {
          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals = this.createLabel = label
        }
      }
    }

    public validateLabelName(label?: string) {
      label = (label || '').replace('criteria:', '')

      const regex = new RegExp('^[a-z0-9][a-z0-9:_-]{3,254}')

      // label should not contain empty spaces
      const whiteSpaceRegex = new RegExp(/\s/)

      const result =
        (label.length > 10 && regex.test(label) && !whiteSpaceRegex.test(label)) ||
        !!this.existingLabels.find((l: UserLabel) => l.name === label)

      this.labelValid = result === true

      this.emitValidate()

      return result
    }

    public removeUsersFromLabel() {
      this.$confirm(
        'Remove selected users?',
        'Are you sure you want to remove the selected users from the label?',
      ).then((confirmed) => {
        if (confirmed) {
          if (
            this.criteria.expression?.oneOf?.$case === 'predicate' &&
            this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
            this.criteria.expression.oneOf.predicate.oneOf.user?.oneOf?.$case === 'hasLabelThat' &&
            this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
          ) {
            this.labelsStore.removeUsersFromLabel(
              this.criteria.metadata?.informative!.labels.labelsEnv || this.cloudEnv,
              this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals,
              this.selectedUsers,
            )

            this.selectedUsers = []
          }
        } else {
          this.selectedUsers = []
        }
      })
    }

    public async appendUsersToLabel(userUids: string[]) {
      if (
        this.criteria?.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals'
      ) {
        const data: { label: string; userUids: string[] } = {
          label: this.criteria?.expression?.oneOf?.predicate?.oneOf.user.oneOf.hasLabelThat.oneOf.equals,
          userUids: userUids,
        }

        const allUsers = await this.labelsStore.addUsersToLabel(this.env, data)

        for (const u of allUsers) {
          if (u.status === 'exists') {
            this.existingUsers.push(u.userUid)
          } else if (u.status === 'not_found') {
            this.notFoundUsers.push(u.userUid)
          }
        }

        this.userText = ''
      }
    }

    public async importUsersToLabel(users: { userUid: string }[]) {
      if (users.length && !this.userImportError) {
        this.importing = true

        await this.appendUsersToLabel(users.map((u) => u.userUid))

        this.importing = false
      }
    }

    public async convertEmailToUserUid(updateSearch?: boolean) {
      const emailForSearch = this.search ? this.search : this.userText

      const uuids = await this.labelsStore.convertEmailsToUids(this.env, [emailForSearch])

      if (updateSearch) {
        this.userText = uuids[0] || this.userText

        this.userAddInputError = !uuids[0] ? 'No account found with given email' : ''
      }

      return uuids
    }

    @Debounce(500)
    public async loadLabelUsers(options: { page: number; itemsPerPage: number; search?: string }) {
      let label = ''

      if (
        this.criteria.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression.oneOf.predicate.oneOf?.$case === 'user' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf?.$case === 'equals' &&
        this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals.length > 0
      ) {
        label = this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals
      }

      const requestPayload: any = {
        page: options?.page ?? 1,
        search: options?.search ?? '',
        label: label ?? this.createLabel,
        itemsPerPage: options?.itemsPerPage ?? 10,
      }

      if (options?.page === 1 && options?.itemsPerPage) {
        requestPayload.path = ''
      } else if (options?.page > this.activePage) {
        requestPayload.path = this.pagination?.next || ''
      } else if (options?.page < this.activePage) {
        requestPayload.path = this.pagination?.prev || ''
      }

      if (!options?.search) {
        this.userSearchInputError = ''
      } else if (options?.search?.includes('@')) {
        const uuids = await this.convertEmailToUserUid()

        if (uuids?.length) {
          requestPayload.search = uuids[0]
        } else {
          this.userSearchInputError = 'No account found with given email'
          return
        }
      }

      this.activePage = options?.page

      this.labelsStore.listEnvLabelUsers(this.env, requestPayload)
    }

    public async updateLabelUsers(label: string) {
      await this.labelsStore.listEnvLabelUsers(this.env, {
        label: label,
      })
    }

    public copyUnAddedUsersToClipboard(users: string[]) {
      navigator.clipboard.writeText(users.join('\n')).then(
        () => {},
        () => {
          console.warn('Failed to copy to the clipboard')
        },
      )
    }
  }

  export default toNative(CriteriaLabel)
</script>
