import { orderBy } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { HttpsCallableResult, getFunctions, httpsCallable } from 'firebase/functions'

import { ListObjectsRequest_DataReturn, ListObjectsRequest_Type } from '@jouzen/feature-mgmt-api/control_service'

import { AppStore } from '#stores'

import { Criteria } from '#types'

let unsubscribeSegments: number | undefined = undefined

const callWithErrorHandling = async function (
  this: any,
  functionName: string,
  data: any,
  clearPreviousError: boolean = true,
): Promise<HttpsCallableResult<unknown> | null> {
  if (clearPreviousError) {
    this.errorMessage = null
  }

  const functions = getFunctions(getApp(), 'europe-west1')

  let response: HttpsCallableResult | null = null

  try {
    response = await httpsCallable(functions, functionName)(data)
  } catch (_error) {
    if (functionName.includes('modify')) {
      this.errorMessage =
        'Unexpected error while saving, UI might show wrong information, please contact Rollout Services squad.'
    } else {
      this.errorMessage =
        'Something went wrong while calling feature management backend, please contact Rollout Services squad.'
    }
  }

  return response || Promise.resolve(null)
}

/**
 * "Features" store definition.
 */
@Store
export class SegmentsStore extends Pinia {
  public loading = false

  public criterias: Criteria[] = []

  public errorMessage: string | null = null

  public async listCriterias(update?: boolean) {
    if (!update) {
      this.loading = true
    }

    const response: any = await callWithErrorHandling.call(
      this,
      'listObjectsFromControlBee',
      {
        type: ListObjectsRequest_Type.NAMED_CRITERIA,
        dataReturn: ListObjectsRequest_DataReturn.ALL_DATA,
      },
      false,
    )

    // TODO: Remove migration mapping once done

    this.criterias = orderBy(response?.data?.namedCriteria || [], (criteria) => criteria.metadata.createdAt, [
      'desc',
    ]).map((criteria) => {
      delete criteria.metadata.informative.labels.creator

      return criteria
    })

    if (!update) {
      this.loading = false
    }

    return response?.data
  }

  public async updateCriteria(data: any) {
    this.loading = true

    const appStore = new AppStore()

    if (!data.metadata.createdAt) {
      data.metadata.owners = appStore.user?.email
    }

    await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
      objectOneOf: { $case: 'namedCriteria', namedCriteria: data },
    })

    this.loading = false

    this.listCriterias()
  }

  public async deleteCriteria(data: any) {
    this.loading = true

    await callWithErrorHandling.call(this, 'modifyObjectInControlBee', {
      delete: true,
      reference: {
        name: data.metadata.name,
        project: data.metadata.project,
        objectType: data.metadata.objectType,
      },
      expectedIteration: data.metadata.iteration,
    })

    this.loading = false

    this.listCriterias()
  }

  public async subscribeToSegments() {
    if (!unsubscribeSegments) {
      this.listCriterias()

      unsubscribeSegments = window.setInterval(
        () => {
          this.listCriterias(true)
        },
        5 * 60 * 1000,
      )
    }
  }

  public async unsubscribeFromSegments() {
    if (unsubscribeSegments) {
      window.clearInterval(unsubscribeSegments)

      unsubscribeSegments = undefined
    }
  }
}
