import { Timestamp as AdminTimestamp } from 'firebase-admin/firestore'
import { Timestamp } from 'firebase/firestore'

import { PhraseProjects } from '#types'

export type AppPlatform = 'ios' | 'android'

export type AppReleaseCandidates = { [platform in AppPlatform]: string }

export type AppBuildTest = {
  commit: string
  testRunUrl: string
  testRunType: string
  createdAt: Timestamp | AdminTimestamp
  updatedAt: Timestamp | AdminTimestamp
  platform: AppPlatform
  testRunOutcome: TestRunOutcomes
}

export type AppBuildTests = {
  [testRunType: string]: AppBuildTest
}

export type ReleaseNoteErrors = {
  translationErrors: string[]
  uploadErrors: string[]
}

export type AppBuild = {
  ecore: string
  commit: string
  version: string
  createdAt: Timestamp | AdminTimestamp
  updatedAt: Timestamp | AdminTimestamp
  buildVersion: string
  platform: AppPlatform
  insightsEngine: string
  insightsContent: string

  // Updated in Waltari when inspected by a user
  crashFreeRate?: string // e.g. '99.964 %'

  // Updated during sync from App/Play Store
  state?: ReleaseStates
  buildId?: string
  tier?: ReleaseTiers

  // Update in case of errors when submitting to App/Play Store
  releaseNoteErrors?: ReleaseNoteErrors

  // iOS only fields
  versionId?: string // populated after a release version is created in App Store Connect
  reviewSubmissionId?: string // populated after review submission is created in App Store Connect
  reviewSubmissionItemId?: string // populated after review submission item is added to the related review submission in App Store Connect

  // Android only fields
  rolloutPercentage?: number
  managedPublishingStatus?: ManagedPublishingStatus // API does not provide this information, it is set manually
}

export type ReleaseSteps = {
  technicalStatusIsValid: boolean
  priorityGroupTesting: boolean
  internalTesting: boolean
  externalTesting: boolean
  releaseNotes: boolean
  review: boolean
  rollout: boolean
}

export type SentryCrashRateRequestData = {
  releaseVersion: string
  platform: AppPlatform
  start: string
  end: string
}

export type ReleaseNotes =
  | {
      jobId: string
      notes: string
      state: ReleaseNoteStates
      updatedAt: Timestamp
      createdAt: Timestamp
      keyId?: string
    }
  | undefined

export type appReleases = { [platform in AppPlatform]: AppBuild | Partial<AppBuild> }

export type ElevateData = {
  version: string
  buildId: string | undefined
  buildVersion: string | undefined
  enReleaseNotes: string | undefined
  platform: AppPlatform
  releaseGroupsOrTracks: (AndroidReleaseTracks | IosReleaseGroups)[]
}

export type AppReviewData = {
  version: string
  versionId?: string
  buildVersion: string
  platform: AppPlatform
  reviewSubmissionId?: string
  reviewSubmissionItemId?: string
}

export type uploadAppReleaseNotesData = {
  keyId: string
  project: PhraseProjects
  platform: AppPlatform
  appVersion: string
}

export type PublicRelease = {
  build: string
  version: string
}

export type PlatformMetadata = {
  releaseCandidate: string
  latestBuildVersion: string
  currentPublicRelease: PublicRelease
  previousPublicRelease: PublicRelease
}

/**
 * Play Store manual publishing status
 */
export enum ManagedPublishingStatus {
  SUBMITTED = 'submitted',
  RELEASED = 'released',
}

export enum ReleaseStepStates {
  TECHNICAL_STATUS,
  PRIORITY_GROUP_TESTING,
  INTERNAL_TESTING,
  EXTERNAL_TESTING,
  RELEASE_NOTES,
  REVIEW,
  ROLLOUT,
}

export enum IosReleaseGroups {
  TIER_1 = '6d2e50d8-f18b-49f3-99e1-8e6033f7b9c4',
  TIER_2 = '6a93cad5-dca9-4cad-8876-ae9777e66010',
  TIER_3 = '444b2de3-2605-45f1-a955-e80d87d90643',
}

export enum AndroidReleaseTracks {
  PRIORITY_GROUP = 'Pre-Alpha',
  TIER_1 = 'internal',
  TIER_2 = 'alpha',
  TIER_3 = 'beta',
  PRODUCTION = 'production',
}

export enum ReleaseTiers {
  UNKNOWN = 'Unknown tier',
  PRIORITY_GROUP_TESTING = 'Priority group only',
  INTERNAL_TESTING = 'Released for tier 1',
  SELECTED_EXTERNAL_TESTING = 'Released for tier 2',
  FULL_EXTERNAL_TESTING = 'Released for tier 3',
  PRODUCTION = 'Production',
}

/**
 * Keys must match values from ReleaseTiers enum, thus unconventional naming
 */
export enum ReleaseTierWeights {
  'Unknown tier' = 0,
  'Priority group only' = 1,
  'Released for tier 1' = 2,
  'Released for tier 2' = 3,
  'Released for tier 3' = 4,
  'Production' = 5,
}

export enum ReleaseStates {
  RELEASE_DISCONTINUED = 'Release discontinued',
  UNKNOWN = 'Unknown status',
  PREPARE_FOR_SUBMISSION = 'Ready for review',
  WAITING_FOR_REVIEW = 'Waiting for review',
  PENDING_FOR_RELEASE = 'Pending for release',
  CURRENT_PUBLIC_RELEASE = 'Current public release',
  PREVIOUS_PUBLIC_RELEASE = 'Previous public release',
}

/**
 * Keys must match values from ReleaseStates enum, thus unconventional naming
 */
export enum ReleaseStateWeights {
  'Release discontinued' = -1,
  'Unknown status' = 0,
  'Ready for review' = 1,
  'Waiting for review' = 2,
  'Pending for release' = 3,
  'Current public release' = 4,
  'Previous public release' = 5,
}

export enum SentryProjects {
  IOS = '4504055881334784',
  ANDROID = '4504197516099584',
}

export enum ReleaseNoteStates {
  DRAFT = 'Draft',
  IN_TRANSLATION = 'In translation',
  TRANSLATED = 'Translated',
  TEMPLATE_TRANSLATION = 'Template translation',
}

export enum ReleaseTypes {
  LATEST = 'current public release',
  PREVIOUS = 'previous public release',
  CANDIDATE = 'release candidate',
}

export enum PlayStoreReleaseStatuses {
  STATUS_UNSPECIFIED = 'statusUnspecified',
  DRAFT = 'draft',
  IN_PROGRESS = 'inProgress',
  HALTED = 'halted',
  COMPLETED = 'completed',
}

export enum TestRunOutcomes {
  SUCCESS = 'success',
  FAILURE = 'failure',
  APPROVED = 'failure (manually approved)',
}

export enum AppBuildFirestorePaths {
  ios = 'releases-appstore-builds',
  android = 'releases-playstore-builds',
}

export enum AppTestFirestorePaths {
  ios = 'releases-appstore-tests',
  android = 'releases-playstore-tests',
}

export enum AppReleaseNotesFirestorePaths {
  ios = 'releases-appstore-texts',
  android = 'releases-playstore-texts',
}
