import { createDefaultTranslatable } from '#views/messages/utilities'

import { Content } from '#types'

export function createDefaultContent(slug: string, type: string, category: string, provider: string): Content {
  const id = slug.replace(/-/g, '_')

  return {
    id: id,
    tags: [],
    type: type,
    cover: '',
    media: slug,
    state: 'draft',
    upload: '',
    vendor: provider,
    category: category,
    title: createDefaultTranslatable(`${id}:explore`, `${type} entry title`),
    description: createDefaultTranslatable(`${id}:explore`, `${type} entry description`),
    duration: 0,
    languages: [],
  }
}

export function fuzzyMatch(a: string, b: string, matchExactString: boolean = false) {
  /**
   * This function matches the following cases
   * 1 letter is missing
   * 1 letter is wrong at any given position
   * 1 additional letter is found at any position
   * 1 letter wrong AND 1 letter missing
   * 1 letter wrong AND 1 letter additional letter
   *
   * It does not match
   * More the 1 letter missing or added
   * More than 1 letter wrong in different positions (not next to each other)
   *
   * Additional/typoed letters must conform to \w which is any letter, digit or underscore [a-zA-Z0-9_]
   */

  const candidates = []

  for (let i = 0; i < a.length; i++) {
    const additional = a.split('')
    additional[i] = '\\w{0,2}'
    if (matchExactString) {
      candidates.push(`^${additional.join('')}$`)
    } else {
      candidates.push(additional.join(''))
    }
  }

  for (const c of candidates) {
    const r = new RegExp(c)
    if (b.match(r)) {
      return true
    }
  }
  return false
}
