<template>
  <StatusHeader :feature="feature" :override="activeOverride" :rollout-view="rolloutView" />
  <v-card>
    <StatusConfig
      v-if="activeOverride"
      :feature="feature"
      :override="activeOverride"
      :overrides="overrides"
      :references="references"
      :rollout-view="rolloutView"
      :preview-only="isLoading || previewOnly"
    />

    <v-card-text class="d-flex flex-column">
      <v-progress-circular
        v-if="isLoading || !activeOverride"
        class="justify-self-center align-self-center ma-16"
        :size="96"
      />

      <template v-else>
        <div class="d-flex flex-row align-center">
          <div class="text-primary text-h6 font-weight-medium pa-4 mb-n2">Rollout plan and configuration</div>

          <v-spacer />

          <v-tooltip
            location="start"
            :text="
              'Rollout was last edited by ' +
              (activeOverride.metadata?.changeRecord?.lastModifiedBy ||
                activeOverride.metadata?.changeRecord?.createdBy ||
                activeOverride.metadata?.informative?.additionalData?.createdBy ||
                'system / unknown user') +
              ' - ' +
              $dayjs(
                activeOverride.metadata?.changeRecord?.lastModifiedAt ||
                  activeOverride.metadata?.changeRecord?.createdAt ||
                  activeOverride.metadata?.informative?.additionalData?.updatedAt ||
                  activeOverride.metadata?.informative?.additionalData?.createdAt,
              ).format('HH:mm on DD MMM YYYY')
            "
          >
            <template #activator="{ props }">
              <v-btn v-bind="props" class="mr-2" icon="mdi-clock-edit-outline" />
            </template>
          </v-tooltip>

          <v-tooltip location="start" text="Show past stages">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="mr-4"
                icon="mdi-calendar-start-outline"
                :active="routeParams.showHistory === 'true'"
                @click="routeParams.showHistory = routeParams.showHistory === 'false' ? 'true' : 'false'"
              />
            </template>
          </v-tooltip>

          <v-select
            v-model="routeParams.timeDisplay"
            rounded
            hide-details
            density="compact"
            label="Time display"
            style="max-width: 230px"
            :items="timeOptions"
          />
        </div>

        <StatusStages
          :feature="feature"
          :override="activeOverride"
          :rollout-view="rolloutView"
          :time-display="routeParams.timeDisplay"
          :show-history="routeParams.showHistory === 'true'"
        />

        <StatusParams :feature="feature" :override="activeOverride" :rollout-view="rolloutView" />

        <v-row class="pa-4 mt-4 d-flex align-start justify-between">
          <v-col class="text-h6 font-weight-medium text-primary pt-4" cols="8">
            Users targeting criteria / segments
          </v-col>

          <v-col v-if="$featureEnabled('estimatorService')" class="align-self-end">
            <v-text-field
              v-model.trim="userUid"
              rounded
              clearable
              density="compact"
              append-inner-icon="mdi-magnify"
              label="Type user UID to check if they should be included..."
              :hint="userUid && !userStateMessage ? 'Press enter or click search icon to perform the search' : ''"
              :loading="runningEstimator"
              :error-messages="searchInputError"
              @click:clear="resetQueryUid()"
              @click:append-inner="queryByUserUid()"
              @keydown.enter="queryByUserUid()"
            >
              <template v-if="userStateMessage" #details>
                <div class="text-body-2 mr-n1">
                  {{ userStateMessage }}

                  <v-icon class="ml-2" :color="userStateInfo(userState).color">
                    {{ userStateInfo(userState).icon }}
                  </v-icon>
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <StatusLabels
          :feature="feature"
          :override="activeOverride"
          :users-filter="filterUid"
          :rollout-view="rolloutView"
          :time-display="routeParams.timeDisplay"
        />

        <CriteriaChart
          v-if="activeOverride && allCriterias.length > 0"
          class="ma-4 mt-12"
          :height="500"
          :criteria="activeOverride.criteria"
          :criterias="allCriterias"
        />
      </template>
    </v-card-text>
  </v-card>

  <StatusControl
    v-if="activeOverride?.metadata?.informative?.labels?.rollout !== 'disable'"
    :feature="feature"
    :override="activeOverride"
    :overrides="overrides"
    :references="references"
    :rollout-view="rolloutView"
  />
</template>

<script lang="ts">
  import { Component, Prop, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { ByUserUidResponse, ByUserUidResponse_User } from '@jouzen/feature-mgmt-api/estimator_service'

  import { timeOptions } from '#views/features/constants'

  import { validateUserUid } from '#views/segments/utilities'

  import { FeaturesStore, SegmentsStore } from '#stores'

  import { Feature, Override, Reference, RolloutTarget } from '#types'

  @Component({})
  class RolloutsInfo extends mixins(RouteParams) {
    @Prop() public active!: number

    @Prop() public feature!: Feature
    @Prop() public overrides!: Override[]
    @Prop() public references!: Reference[]

    @Prop() public previewOnly!: boolean

    @Prop() public rolloutView!: RolloutTarget

    public routeParams = {
      timeDisplay: 'local',
      showHistory: 'false',
    }

    public userState = 0

    public userUid = ''
    public filterUid = ''

    public userStateMessage = ''
    public searchInputError = ''

    public runningEstimator = false

    public readonly timeOptions = timeOptions

    public readonly validateUserUid = validateUserUid

    private readonly featuresStore = new FeaturesStore()
    private readonly segmentsStore = new SegmentsStore()

    public get isLoading() {
      return this.featuresStore.loading
    }

    public get allCriterias() {
      return this.segmentsStore.criterias
    }

    public get activeOverride() {
      return this.overrides[this.active]
    }

    @Watch('active')
    protected activeChanged() {
      this.resetQueryUid()
    }

    @Watch('userUid')
    protected userUidChanged() {
      this.searchInputError = ''

      this.userStateMessage = ''
    }

    @Watch('rolloutView')
    protected rolloutViewChanged() {
      this.resetQueryUid()
    }

    public userStateInfo(userState: number) {
      switch (userState) {
        case 0: // unknown
          return { color: 'info', icon: 'mdi-account-question' }
        case 1: // affected
          return { color: 'success', icon: 'mdi-account-star' }
        case 2: // not affected
          return { color: 'info', icon: 'mdi-account-remove' }
        default: // superseded
          return { color: 'warning', icon: 'mdi-account-remove' }
      }
    }

    public resetQueryUid() {
      this.userUid = ''
      this.filterUid = ''

      this.userStateMessage = ''
    }

    public async queryByUserUid() {
      if (this.validateUserUid(this.userUid) !== true) {
        this.searchInputError = 'Invalid user UID'

        return
      }

      this.filterUid = this.userUid

      this.runningEstimator = true

      const requestPayload = {
        expression: this.activeOverride.criteria,
        userUid: this.userUid,
        template: this.activeOverride?.metadata?.informative?.labels?.template,
      }

      const resp = (await this.featuresStore.fetchDataFromEstimatorService(requestPayload)) as ByUserUidResponse

      this.runningEstimator = false

      const queriedUser = resp?.users?.find((user: ByUserUidResponse_User) => user.uid === this.userUid)

      switch (queriedUser?.affected) {
        case 1:
          this.userState = 1
          this.userStateMessage = 'User associated with the UID is affected by the criteria'
          break
        case 2:
          this.userState = 2
          this.userStateMessage = 'User associated with the UID is not affected by the criteria'
          break
        case 3:
          this.userState = 3
          this.userStateMessage = 'User associated with the UID is superseded by the criteria'
          break
        default:
          this.userState = 0
          this.userStateMessage = 'It is unknown whether the user is affected by the criteria.'
          break
      }
    }
  }
  export default toNative(RolloutsInfo)
</script>
