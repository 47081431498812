import { Expression } from '@jouzen/feature-mgmt-api/criteria'
import { Feature_Phase } from '@jouzen/feature-mgmt-api/feature'
import { Context, Project, Project_StaticId, TopLevel_ObjectType } from '@jouzen/feature-mgmt-api/metadata'
import { Group, Override_State } from '@jouzen/feature-mgmt-api/override'
import { Value_FixedPoint_Sign } from '@jouzen/feature-mgmt-api/parameter'
import { MobileDevice_Os, Root } from '@jouzen/feature-mgmt-api/predicates'
import {
  Rollout,
  Rollout_Stage,
  Rollout_Stage_StageState,
  Rollout_Stage_StageType,
  Rollout_State,
} from '@jouzen/feature-mgmt-api/rollout'

import { AppVersions, Feature, Override, RolloutTarget } from '#types'

/**
 * - If entity is not specified, return an empty array.
 * - If target is specified, assume entity is a Feature and return the override(s) for the target.
 * - If target is not specified, assume entity is an override or group and return the overrides.
 */
function getOverrides(
  entity?: Feature | Override | Group | Record<string, any>,
  target?: RolloutTarget | unknown,
): (Override | undefined)[] {
  if (!entity) {
    return []
  }

  if (typeof target === 'string') {
    const feature = entity as Feature
    const hasOneRollout = target !== 'release' || feature.metadata!.informative?.labels?.strategy !== 'advanced'

    if (hasOneRollout) {
      return [findFeatureOverride(feature, target)]
    }

    return [findFeatureOverride(feature, 'release-ios'), findFeatureOverride(feature, 'release-android')]
  }

  if (Object.hasOwn(entity, 'overrides')) {
    return [...(entity as Group).overrides]
  }

  return [entity as Override]
}

export function fixedPointToString(fp: any) {
  const decimals = fp?.thousandthPart !== undefined ? 3 : 6

  const stringValue = fp
    ? parseFloat(
        [
          ((fp.sign === Value_FixedPoint_Sign.NEGATIVE ? -1 : 1) * (fp.integerPart || 0)).toString(),
          ('000000' + (fp.thousandthPart || fp.millionthPart || 0)).slice(-decimals),
        ].join('.'),
      ).toString()
    : ''

  return !stringValue || decimals === 3 || stringValue.split('.').length > 1 ? stringValue : stringValue + '.0'
}

export function stringToFixedPoint(value: string, decimals: 3 | 6, sign?: boolean) {
  const fp: any = {
    integerPart: 0,
  }

  if (sign) {
    fp.sign = value[0] === '-' ? Value_FixedPoint_Sign.NEGATIVE : Value_FixedPoint_Sign.POSITIVE
  }

  const fixedPoint = isNaN(parseFloat(value.replace(',', '.')))
    ? ''
    : Math.abs(parseFloat(value.replace(',', '.')))
        .toFixed(decimals)
        .toString()
        .split('.')

  fp.integerPart = parseInt(fixedPoint[0])

  if (decimals === 3) {
    fp.thousandthPart = parseInt(fixedPoint[1])
  } else {
    fp.millionthPart = parseInt(fixedPoint[1])
  }

  return fixedPoint ? fp : undefined
}

export function isFeatureActive(feature: Feature, target: RolloutTarget | string) {
  const override = findFeatureOverride(feature, target)

  return (
    override?.rolloutOneOf?.$case === 'rollout' &&
    !!override?.rolloutOneOf.rollout.stages.find((s: any) => s.state === Rollout_Stage_StageState.CURRENT)
  )
}

export function isFeatureEnabled(feature: Feature, target: RolloutTarget | string) {
  const override = findFeatureOverride(feature, target)

  return (
    override?.rolloutOneOf?.$case === 'rollout' &&
    (override?.rolloutOneOf?.rollout?.state || 0) > 1 &&
    override?.parameters?.enabled?.oneOf?.$case === 'boolean' &&
    override?.parameters?.enabled?.oneOf?.boolean === true &&
    !!override?.rolloutOneOf?.rollout?.stages.find((s: any) => s.state === Rollout_Stage_StageState.CURRENT)
  )
}

export function hasAdvancedRollouts(feature: Feature) {
  return !!feature?.overrideList.find(
    (o) =>
      !(o.oneOf?.$case === 'group' ? o.oneOf.group.overrides : [o.oneOf!.override]).find(
        (o) =>
          o.metadata!.informative?.labels?.rollout ||
          o.state === Override_State.DISABLED ||
          (o.rolloutOneOf?.$case === 'rollout' && o.rolloutOneOf.rollout.state === Rollout_State.INACTIVE),
      ),
  )
}

export function getFutureRolloutStage(override: Override) {
  return (
    (override?.rolloutOneOf?.$case === 'rollout' &&
      override?.rolloutOneOf.rollout.stages.find((s: any) => s.state === Rollout_Stage_StageState.FUTURE)) ||
    null
  )
}

export function hasFutureRolloutStages(overrides: Override[]) {
  return overrides.some((o) => !!getFutureRolloutStage(o))
}

export function findFeatureOverride(feature: Feature, target: RolloutTarget | string): Override | undefined {
  for (const o of feature.overrideList) {
    if (o.oneOf?.$case === 'group') {
      for (const go of o.oneOf.group.overrides) {
        if (go.metadata!.uid === target || go.metadata!.informative?.labels?.rollout === target) {
          return go
        }
      }
    } else if (o.oneOf?.$case === 'override') {
      if (
        o.oneOf.override.metadata!.uid === target ||
        o.oneOf.override.metadata!.informative?.labels?.rollout === target
      ) {
        return o.oneOf.override
      }
    }
  }

  return undefined
}

export function featureOverrideState(feature: Feature, target: RolloutTarget): 'ENABLED' | 'DISABLED' | ''
export function featureOverrideState(override: Override | Override, ignoreState?: boolean): 'ENABLED' | 'DISABLED' | ''
export function featureOverrideState(featureOrOverride: Record<string, any>, targetOrIgnoreState?: unknown) {
  const overrides = getOverrides(featureOrOverride, targetOrIgnoreState)

  const isEnabled = overrides.some((override) => {
    return (
      override?.rolloutOneOf?.$case === 'rollout' &&
      override?.parameters?.enabled?.oneOf?.$case === 'boolean' &&
      override?.parameters.enabled.oneOf.boolean &&
      (targetOrIgnoreState === true || (override?.rolloutOneOf.rollout.state || 0) > 1)
    )
  })

  const isDisabled = overrides.some((override) => {
    return (
      override?.rolloutOneOf?.$case === 'rollout' &&
      override?.parameters?.enabled?.oneOf?.$case === 'boolean' &&
      !override?.parameters.enabled.oneOf.boolean &&
      (targetOrIgnoreState === true || (override?.rolloutOneOf.rollout.state || 0) > 1)
    )
  })

  return isEnabled ? 'ENABLED' : isDisabled ? 'DISABLED' : ''
}

export function overrideRolloutStatus(feature: Feature, target: RolloutTarget): string
export function overrideRolloutStatus(override?: Override | Group): string
export function overrideRolloutStatus(featureOrOverride?: Record<string, any>, targetOrNotSet?: RolloutTarget): string {
  const overrides = getOverrides(featureOrOverride, targetOrNotSet)

  const statuses = overrides
    .filter((o) => o !== undefined && o.rolloutOneOf?.$case === 'rollout')
    .map((o) => {
      if (!o) {
        return 'INACTIVE'
      }

      // .filter() method ensures we only have rollouts, annoyingly TS doesn't catch that...
      const oneOf = o.rolloutOneOf as { rollout: Rollout }
      const rolloutState = oneOf.rollout.state

      if (o.state === Override_State.DISABLED) {
        return 'DISABLED'
      } else if (rolloutState === Rollout_State.ONGOING) {
        return 'ACTIVE'
      } else if (rolloutState === Rollout_State.AUTO_PAUSED || rolloutState === Rollout_State.USER_PAUSED) {
        return 'PAUSED'
      } else if (rolloutState === Rollout_State.INACTIVE) {
        return 'INACTIVE'
      } else if (rolloutState === Rollout_State.FINALIZED) {
        return 'FINALIZED'
      } else if (rolloutState === Rollout_State.SCHEDULED_NOT_STARTED) {
        return 'SCHEDULED'
      } else {
        return 'INACTIVE'
      }
    })

  if (statuses.length <= 1) {
    return statuses[0] || 'INACTIVE'
  }

  if (statuses.every((s) => s === 'DISABLED')) {
    return 'DISABLED'
  } else if (statuses.every((s) => s === 'INACTIVE')) {
    return 'INACTIVE'
  }

  if (statuses.includes('FINALIZED') && statuses.find((s) => s !== 'FINALIZED')) {
    return 'ACTIVE'
  } else if (statuses.includes('ACTIVE') && statuses.find((s) => s !== 'ACTIVE')) {
    return 'ACTIVE'
  } else if (statuses.includes('SCHEDULED') && statuses.find((s) => s !== 'SCHEDULED')) {
    return 'SCHEDULED'
  } else if (statuses.includes('PAUSED') && statuses.find((s) => s !== 'PAUSED')) {
    return 'PAUSED'
  } else if (statuses.includes('INACTIVE') && statuses.find((s) => s !== 'INACTIVE')) {
    return 'INACTIVE'
  }

  return statuses[0]
}

export function namedCriteriasInverted(override: Override) {
  if (override.criteria?.oneOf?.$case === 'and' && override.criteria?.oneOf?.and.expressions) {
    const i = override.metadata!.informative?.labels.template.startsWith('percentage') ? 0 : 1

    const expression = override.criteria.oneOf.and.expressions[i]

    if (
      expression?.oneOf?.$case === 'and' &&
      expression?.oneOf?.and?.expressions?.find(
        (e: any) =>
          (e.oneOf?.$case === 'or' && e.oneOf.or.expressions[0]?.oneOf?.$case === 'not') ||
          (e.oneOf?.$case === 'and' && e.oneOf.and.expressions[0]?.oneOf?.$case === 'not'),
      )
    ) {
      return true
    }
  }

  return false
}

export function namedCriteriasOperator(override: Override) {
  if (override.criteria?.oneOf?.$case === 'and' && override.criteria?.oneOf?.and.expressions) {
    const i = override.metadata!.informative?.labels.template.startsWith('percentage') ? 0 : 1

    const expression = override.criteria.oneOf.and.expressions[i]

    return expression?.oneOf?.$case === 'and' &&
      expression?.oneOf?.and?.expressions?.find((e: any) => e.oneOf?.$case === 'or')
      ? 'or'
      : 'and'
  }

  return 'and'
}

export function forEachFeatureOverride(feature: Feature, callback: (override: Override) => void) {
  for (const override of feature.overrideList) {
    if (override.oneOf?.$case === 'group') {
      for (const o of override.oneOf.group.overrides) {
        callback(o)
      }
    } else if (override.oneOf?.$case === 'override') {
      callback(override.oneOf.override)
    }
  }
}

export function forEachNamedCriteria(override: Override | Override, callback: (criteria: string) => void) {
  if (override.criteria?.oneOf?.$case === 'and' && override.criteria?.oneOf?.and.expressions) {
    const e = override.metadata?.informative?.labels.template.startsWith('percentage')
      ? override.criteria.oneOf.and.expressions[0] // Percentage rollout
      : override.criteria.oneOf.and.expressions[1] // Experiment rollout

    const expressions =
      e.oneOf?.$case === 'or' ? e.oneOf.or.expressions : e.oneOf?.$case === 'and' ? e.oneOf.and.expressions : []

    function loopExpressions(expressions: any) {
      for (const expression of expressions) {
        if (
          expression.oneOf?.$case === 'predicate' &&
          expression.oneOf?.predicate?.oneOf?.$case === 'generic' &&
          expression.oneOf?.predicate.oneOf.generic.oneOf?.$case === 'namedCriteria'
        ) {
          callback(expression.oneOf.predicate.oneOf.generic.oneOf?.namedCriteria.name)
        } else if (
          expression.oneOf?.$case === 'not' &&
          expression.oneOf?.not?.expression?.oneOf?.$case === 'predicate' &&
          expression.oneOf?.not?.expression?.oneOf?.predicate?.oneOf?.$case === 'generic' &&
          expression.oneOf?.not?.expression?.oneOf?.predicate.oneOf?.generic.oneOf?.$case === 'namedCriteria'
        ) {
          callback(expression.oneOf?.not.expression.oneOf.predicate.oneOf.generic.oneOf.namedCriteria.name)
        } else if (expression.oneOf?.$case === 'and' || expression.oneOf?.$case === 'or') {
          loopExpressions(
            expression.oneOf?.$case === 'or'
              ? expression.oneOf.or.expressions
              : e.oneOf?.$case === 'and'
                ? expression.oneOf.and.expressions
                : [],
          )
        }
      }
    }

    loopExpressions(expressions)
  }
}

export function forEachPredicateExpression(criteria: any, callback: (predicate: Root) => void) {
  if (criteria?.$case === 'predicate') {
    callback(criteria?.predicate)
  } else if (criteria?.$case === 'not') {
    forEachPredicateExpression(criteria.not.expression.oneOf, callback)
  } else if (criteria?.$case === 'and' || criteria?.$case === 'or') {
    criteria[criteria?.$case].expressions.forEach((expression: Expression) => {
      forEachPredicateExpression(expression?.oneOf, callback)
    })
  }
}

export function currentEnvRolloutTarget(feature: Feature, target: RolloutTarget) {
  switch (target) {
    case 'sandbox':
      return 'sandbox'

    case 'staging':
    case 'experimental':
      if (overrideRolloutStatus(feature, 'staging') !== 'INACTIVE') {
        return 'staging'
      } else {
        return 'experimental'
      }

    case 'release':
    case 'ouranians':
    case 'release-ios':
    case 'release-android':
      if (overrideRolloutStatus(feature, 'release') !== 'INACTIVE') {
        return 'release'
      } else {
        return 'ouranians'
      }

    default:
      return target
  }
}

// Data creation helpers

export function createFeature(): Feature {
  return {
    phase: Feature_Phase.ACTIVE,
    metadata: {
      name: '',
      project: {
        oneOf: {
          $case: 'apiStatic',
          apiStatic: Project_StaticId.UNKNOWN,
        },
      },
      informative: {
        labels: {
          team: '',
          contact: '',
          creator: '',
          project: '',
          strategy: '',
          template: '',
        },
        description: '',
        displayName: '',
        referenceUrls: [''],
        additionalData: {},
      },
      contextSpec: {
        oneOf: {
          $case: 'contexts',
          contexts: {
            contexts: [Context.OURA_USER],
          },
        },
      },
      changeRecord: {},
      accessControl: [],
      objectType: TopLevel_ObjectType.FEATURE,
    },
    parameters: [
      {
        name: 'enabled',
        deprecated: false,
        description: 'Controls if the feature is enabled or not',
        type: { oneOf: { $case: 'boolean', boolean: { defaultValue: false } } },
      },
    ],
    overrideList: [],
    deprecations: [],
    protection: undefined,
  }
}

export function createOverride(feature: any, uid?: string): Override {
  const featureParameters: any = {}

  if (feature) {
    feature!.parameters.forEach(
      (p: any) =>
        (featureParameters[p.name] = {
          oneOf: {
            $case: p.type.oneOf.$case,
            [p.type.oneOf.$case]: p.type.oneOf[p.type.oneOf.$case].defaultValue,
          },
        }),
    )

    featureParameters.enabled.oneOf.boolean = true
  }

  const override = {
    uid: '',
    state: Override_State.ACTIVE,
    informative: undefined,
    metadata: {
      uid,
      informative: {
        labels: {
          template: 'percentage-sandbox',
          project: feature ? feature.metadata.informative.labels.project : '',
        },
        description: '',
        displayName: '',
        referenceUrls: [''],
        additionalData: {},
      },
      accessControl: [],
    },
    parameters: featureParameters,
    rolloutOneOf: {
      $case: 'rollout',
      rollout: {
        stages: [createRolloutStage(100)],
      },
    },
    criteria: {
      oneOf: {
        $case: 'and',
        and: {
          expressions: [
            {
              oneOf: {
                $case: 'and',
                and: {
                  expressions: [createNamedCriteria('app_flavor_sandbox')],
                },
              },
            },
          ],
        },
      },
    },
  } as Override

  return override
}

export function createRolloutStage(percent: number, days?: number) {
  if (percent === 100) {
    return {
      type: Rollout_Stage_StageType.FINALIZING,
      percentile: {
        integerPart: 100,
        thousandthPart: 0,
      },
    } as Rollout_Stage
  } else if (days === undefined) {
    return {
      type: Rollout_Stage_StageType.ACTUATED,
      percentile: {
        integerPart: percent,
        thousandthPart: 0,
      },
    } as Rollout_Stage
  } else {
    return {
      type: Rollout_Stage_StageType.FIXED_LENGTH,
      duration: {
        nanos: 0,
        seconds: ((days || 1) * 24 * 60 * 60).toString(),
      },
      percentile: {
        integerPart: percent,
        thousandthPart: 0,
      },
    } as Rollout_Stage
  }
}

export function createNamedCriteria(name: string, project?: Project) {
  return {
    oneOf: {
      $case: 'predicate',
      predicate: {
        oneOf: {
          $case: 'generic',
          generic: {
            oneOf: {
              $case: 'namedCriteria',
              namedCriteria: {
                name: name,
                project: project || {
                  oneOf: {
                    $case: 'apiStatic',
                    apiStatic: Project_StaticId.COMMON,
                  },
                },
                objectType: TopLevel_ObjectType.NAMED_CRITERIA,
              },
            },
          },
        },
      },
    },
  } as Expression
}

export function createUserRangeCriteria(index: number, value: number) {
  return {
    oneOf: {
      $case: 'predicate',
      predicate: {
        oneOf: {
          $case: 'user',
          user: {
            oneOf: {
              $case: 'range',
              range: {
                lowerBound: {
                  integerPart: index * Math.floor(100 / value),
                  thousandthPart: 0,
                },
                upperBound: {
                  integerPart: (index + 1) * Math.floor(100 / value),
                  thousandthPart: 0,
                },
              },
            },
          },
        },
      },
    },
  } as Expression
}

export function createAppVersionCriteria(os: string, versions: AppVersions) {
  switch (os) {
    case 'ios':
      return {
        oneOf: {
          $case: 'and',
          and: {
            expressions: [
              {
                oneOf: {
                  $case: 'predicate',
                  predicate: {
                    oneOf: {
                      $case: 'client',
                      client: {
                        oneOf: {
                          $case: 'device',
                          device: {
                            oneOf: {
                              $case: 'os',
                              os: MobileDevice_Os.IOS,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                oneOf: {
                  $case: 'predicate',
                  predicate: {
                    oneOf: {
                      $case: 'client',
                      client: {
                        oneOf: {
                          $case: 'mobileApp',
                          mobileApp: {
                            oneOf: {
                              $case: 'version',
                              version: {
                                oneOf: {
                                  $case: 'min',
                                  min: {
                                    major: versions.ios.major,
                                    minor: versions.ios.minor,
                                    patch: versions.ios.patch,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      } as Expression

    case 'android':
      return {
        oneOf: {
          $case: 'and',
          and: {
            expressions: [
              {
                oneOf: {
                  $case: 'predicate',
                  predicate: {
                    oneOf: {
                      $case: 'client',
                      client: {
                        oneOf: {
                          $case: 'device',
                          device: {
                            oneOf: {
                              $case: 'os',
                              os: MobileDevice_Os.ANDROID,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                oneOf: {
                  $case: 'predicate',
                  predicate: {
                    oneOf: {
                      $case: 'client',
                      client: {
                        oneOf: {
                          $case: 'mobileApp',
                          mobileApp: {
                            oneOf: {
                              $case: 'version',
                              version: {
                                oneOf: {
                                  $case: 'min',
                                  min: {
                                    major: versions.android.major,
                                    minor: versions.android.minor,
                                    patch: versions.android.patch,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      } as Expression

    default:
      return {
        oneOf: {
          $case: 'and',
          and: {
            expressions: [
              {
                oneOf: {
                  $case: 'predicate',
                  predicate: {
                    oneOf: {
                      $case: 'client',
                      client: {
                        oneOf: {
                          $case: 'mobileApp',
                          mobileApp: {
                            oneOf: {
                              $case: 'version',
                              version: {
                                oneOf: {
                                  $case: 'min',
                                  min: {
                                    major: Math.min(versions.ios.major, versions.android.major),
                                    minor: Math.min(versions.ios.minor, versions.android.minor),
                                    patch: Math.min(versions.ios.patch, versions.android.patch),
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
      } as Expression
  }
}

export function updateOverridesFromTemplate(overrides: Override[], template: string, versions?: AppVersions) {
  const defaultVersion = {
    android: { major: 5, minor: 0, patch: 0 },
    ios: { major: 5, minor: 0, patch: 0 },
  }

  overrides.forEach((override, index) => {
    override.metadata!.informative!.labels.template = template.replace('devices', 'ouranians')

    switch (template) {
      case 'percentage-sandbox':
        override.metadata!.informative!.displayName = 'Sandbox apps'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [createNamedCriteria('app_flavor_sandbox')]
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
        }

        break

      case 'percentage-staging':
        override.metadata!.informative!.displayName = 'Staging app'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [createNamedCriteria('app_flavor_staging')]

          override.criteria.oneOf.and.expressions.push({
            oneOf: {
              $case: 'or',
              or: {
                expressions: [createAppVersionCriteria('', versions || defaultVersion)],
              },
            },
          })
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
        }

        break

      case 'percentage-release':
        override.metadata!.informative!.displayName = 'Release app (All)'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [
            createNamedCriteria('app_flavor_production'),
          ]

          override.criteria.oneOf.and.expressions.push({
            oneOf: {
              $case: 'or',
              or: {
                expressions: [
                  createAppVersionCriteria('ios', versions || defaultVersion),
                  createAppVersionCriteria('android', versions || defaultVersion),
                ],
              },
            },
          })
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [
            createRolloutStage(10, 7),
            createRolloutStage(50, 7),
            createRolloutStage(100),
          ]
        }

        break

      case 'percentage-devices':
      case 'percentage-ouranians':
        override.metadata!.informative!.displayName = 'Ouranians only'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [
            createNamedCriteria('app_flavor_production'),
            template === 'percentage-ouranians'
              ? createNamedCriteria('all_ouranians_group')
              : createNamedCriteria('system_testing_devices'),
          ]

          override.criteria.oneOf.and.expressions.push({
            oneOf: {
              $case: 'or',
              or: {
                expressions: [
                  createAppVersionCriteria('ios', versions || defaultVersion),
                  createAppVersionCriteria('android', versions || defaultVersion),
                ],
              },
            },
          })
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
        }

        break

      case 'percentage-experimental':
        override.metadata!.informative!.displayName = 'Experimental app'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [
            createNamedCriteria('app_flavor_experimental'),
          ]
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
        }

        break

      case 'percentage-release-ios':
        override.metadata!.informative!.displayName = 'Release app (iOS)'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [
            createNamedCriteria('app_flavor_production'),
          ]

          override.criteria.oneOf.and.expressions.push({
            oneOf: {
              $case: 'or',
              or: {
                expressions: [createAppVersionCriteria('ios', versions || defaultVersion)],
              },
            },
          })
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [
            createRolloutStage(10, 7),
            createRolloutStage(50, 7),
            createRolloutStage(100),
          ]
        }

        break

      case 'percentage-release-android':
        override.metadata!.informative!.displayName = 'Release app (Android)'

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0].oneOf.and.expressions = [
            createNamedCriteria('app_flavor_production'),
          ]

          override.criteria.oneOf.and.expressions.push({
            oneOf: {
              $case: 'or',
              or: {
                expressions: [createAppVersionCriteria('android', versions || defaultVersion)],
              },
            },
          })
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [
            createRolloutStage(10, 7),
            createRolloutStage(50, 7),
            createRolloutStage(100),
          ]
        }

        break

      case 'experiment-ab-testing':
      case 'experiment-mv-testing':
        if (!override.metadata!.informative!.displayName) {
          override.metadata!.informative!.displayName = `Group ${'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[index]}`
        }

        if (
          override.criteria?.oneOf?.$case === 'and' &&
          override.criteria?.oneOf?.and.expressions[0] &&
          override.criteria?.oneOf?.and.expressions[0].oneOf?.$case === 'and'
        ) {
          override.criteria.oneOf.and.expressions[0] = createUserRangeCriteria(index, overrides.length)

          if (override.criteria.oneOf.and.expressions.length === 1) {
            override.criteria.oneOf.and.expressions.push({
              oneOf: {
                $case: 'and',
                and: {
                  expressions: [createNamedCriteria('app_flavor_production')],
                },
              },
            })

            override.criteria.oneOf.and.expressions.push({
              oneOf: {
                $case: 'or',
                or: {
                  expressions: [
                    createAppVersionCriteria('ios', versions || defaultVersion),
                    createAppVersionCriteria('android', versions || defaultVersion),
                  ],
                },
              },
            })
          }
        }

        if (override.rolloutOneOf?.$case === 'rollout') {
          override.rolloutOneOf.rollout.stages = [createRolloutStage(100)]
        }

        break
    }
  })
}
