import { sortBy } from 'lodash-es'

import { InsightCategory, StateItem } from '#types'

export const insightGroups = sortBy(
  [
    { value: 'introducing_oura', title: 'Introducing Oura' },
    { value: 'recovery_mode', title: 'Recovery Mode' },
    { value: 'rest_mode', title: 'Rest Mode' },
    { value: 'bedtime', title: 'Bedtime' },
    { value: 'weekly_summary', title: 'Weekly Summary' },

    { value: 'pregnancy', title: 'Pregnancy' },

    { value: 'stress', title: 'Stress' },
    { value: 'proactive_coping', title: 'Proactive Coping' },
    { value: 'daily_practices', title: 'Daily Practices' },
    { value: 'breathing_exercises', title: 'Breathing Exercises' },
    { value: 'physical_rest', title: 'Physical Rest' },
    { value: 'recovery', title: 'Recovery' },
    { value: 'mastery_experiences', title: 'Mastery Experiences' },
    { value: 'physical_activity', title: 'Physical Activity' },
    { value: 'meditation', title: 'Meditation' },
    { value: 'mental_rest', title: 'Mental Rest' },
    { value: 'mindful_activity', title: 'Mindful Activity' },
    { value: 'cognitive_reframing', title: 'Cognitive Reframing' },
    { value: 'social_support', title: 'Social Support' },
    { value: 'self_compassion', title: 'Self-Compassion' },
    { value: 'purposeful_free_time', title: 'Purposeful Free Time' },
  ],
  'title',
)

const insightCategorySnippet: InsightCategory = { value: 'snippet', title: 'Snippet' }
const insightCategorySleep: InsightCategory = { value: 'sleep', title: 'Sleep' }
const insightCategoryActivity: InsightCategory = { value: 'activity', title: 'Activity' }
const insightCategoryReadiness: InsightCategory = { value: 'readiness', title: 'Readiness' }
const insightCategorySlideshow: InsightCategory = { value: 'slideshow', title: 'Slideshow' }
const insightCategoryOnboarding: InsightCategory = { value: 'onboarding', title: 'Onboarding' }
const insightCategoryAnniversary: InsightCategory = { value: 'anniversary', title: 'Anniversary' }
const insightCategoryAADReward: InsightCategory = { value: 'aad_reward', title: 'AAD Reward' }
const insightCategoryCTPReward: InsightCategory = { value: 'ctp_reward', title: 'CTP Reward' }
const insightCategoryWorkoutHRReward: InsightCategory = { value: 'workout_hr_reward', title: 'Workout HR Reward' }
const insightCategoryHRM: InsightCategory = { value: 'hrm', title: 'Health Risk Management' }
const insightCategoryDHRDaytime: InsightCategory = { value: 'dhr_daytime', title: 'Daytime HR Mini Insight' }
const insightCategoryDHRNighttime: InsightCategory = { value: 'dhr_nighttime', title: 'Nighttime HR Mini Insight' }
const insightCategoryDHRRestorativeTime: InsightCategory = {
  value: 'dhr_restorative_time',
  title: 'Restorative Time Mini Insight',
}
const insightCategoryCyclePhases: InsightCategory = { value: 'cycle_phases', title: 'Cycle phases Mini Insight' }
const insightCategoryTrendsInHome: InsightCategory = { value: 'trends_in_home', title: 'Trends in Home Mini Insight' }
const insightCategoryDaytimeStress: InsightCategory = { value: 'daytime_stress', title: 'Daytime Stress Mini Insight' }
const insightCategoryDaytimeStressEvening: InsightCategory = {
  value: 'daytime_stress_evening',
  title: 'Daytime Stress evening Mini Insight',
}
const insightCategoryDaytimeStressPastDay: InsightCategory = {
  value: 'daytime_stress_past_day',
  title: 'Daytime Stress past day Mini Insight',
}

const insightCategoryBedtimeGuidance: InsightCategory = { value: 'bedtime_guidance', title: 'Bedtime Guidance' }

const insightCategoryResilience: InsightCategory = { value: 'resilience', title: 'Resilience Mini Insight' }
const insightCategoryResilienceTip: InsightCategory = { value: 'resilience_tip', title: 'Resilience Tip Cards' }

const insightCategoryCardioCapacity: InsightCategory = {
  value: 'cardio_capacity',
  title: 'Cardio capacity Mini Insight',
}
const insightCategoryCardioCapacityTip: InsightCategory = {
  value: 'cardio_capacity_tip',
  title: 'Cardio Capacity Tip Cards',
}

const insightCategoryVascularAge: InsightCategory = { value: 'vascular_age', title: 'Vascular Age Mini Insight' }
const insightCategoryVascularAgeTip: InsightCategory = { value: 'vascular_age_tip', title: 'Vascular Age Tip Cards' }

const insightCategoryHeartHealthTip: InsightCategory = { value: 'heart_health_tip', title: 'Heart Health Tip Cards' }

const insightCategoryOther: InsightCategory = { value: 'other', title: 'Other' }

export const insightCategories = sortBy(
  [
    insightCategorySnippet,
    insightCategorySleep,
    insightCategoryActivity,
    insightCategoryReadiness,
    insightCategoryAnniversary,
    insightCategoryAADReward,
    insightCategoryCTPReward,
    insightCategoryWorkoutHRReward,
    insightCategoryHRM,
    insightCategoryDHRDaytime,
    insightCategoryDHRNighttime,
    insightCategoryDHRRestorativeTime,
    insightCategoryTrendsInHome,
    insightCategoryCyclePhases,
    insightCategoryDaytimeStress,
    insightCategoryDaytimeStressEvening,
    insightCategoryDaytimeStressPastDay,
    insightCategoryResilience,
    insightCategoryCardioCapacity,
    insightCategoryVascularAge,
    insightCategoryBedtimeGuidance,
    insightCategoryOther,
  ],
  'text',
)

export const tipthemesCategories = sortBy(
  [
    insightCategoryResilienceTip,
    insightCategoryCardioCapacityTip,
    insightCategoryVascularAgeTip,
    insightCategoryHeartHealthTip,
  ],
  'text',
)

export const slideshowCategories = sortBy([{ value: 'slideshow', title: 'Slideshow' }], 'text')

export const workspaceCategories = sortBy(
  [
    insightCategorySnippet,
    insightCategorySleep,
    insightCategoryActivity,
    insightCategoryReadiness,
    insightCategorySlideshow,
    insightCategoryOnboarding,
    insightCategoryAnniversary,
    insightCategoryAADReward,
    insightCategoryCTPReward,
    insightCategoryWorkoutHRReward,
    insightCategoryDHRDaytime,
    insightCategoryDHRNighttime,
    insightCategoryDHRRestorativeTime,
    insightCategoryTrendsInHome,
    insightCategoryCyclePhases,
    insightCategoryDaytimeStress,
    insightCategoryDaytimeStressEvening,
    insightCategoryDaytimeStressPastDay,
    insightCategoryResilience,
    insightCategoryResilienceTip,
  ],
  'text',
)

/**
 * This set of categories are allowed to have a minimum interval of 0.
 * By default all other categories have minimum interval of 1.
 * https://jouzen.atlassian.net/browse/RTECH-4
 */
export const insightCategoriesThatAllowZeroInterval = [
  insightCategoryAADReward,
  insightCategoryActivity,
  insightCategoryCTPReward,
  insightCategoryDHRDaytime,
  insightCategoryDHRNighttime,
  insightCategoryDHRRestorativeTime,
  insightCategoryTrendsInHome,
  insightCategoryOther,
  insightCategoryReadiness,
  insightCategorySleep,
  insightCategoryWorkoutHRReward,
  insightCategoryDaytimeStress,
  insightCategoryDaytimeStressEvening,
  insightCategoryResilience,
  insightCategoryResilienceTip,
  insightCategoryDaytimeStressPastDay,
  insightCategoryCardioCapacity,
  insightCategoryCardioCapacityTip,
  insightCategoryVascularAge,
  insightCategoryVascularAgeTip,
  insightCategoryCyclePhases,
]

export const insightStates: StateItem[] = [
  { title: 'Draft', value: 'draft', color: '#e0e0e0', textColor: 'black' },
  { title: 'Experimental', value: 'experimental', color: 'purple', textColor: 'white' },
  { title: 'Production', value: 'production', color: 'black', textColor: 'white' },
  { title: 'Archived', value: 'archived', color: 'grey', textColor: 'white' },
]

export const messageStates: StateItem[] = [
  { title: 'Draft', value: 'draft', color: '#e0e0e0', textColor: 'black' },
  { title: 'Experimental', value: 'experimental', color: 'purple', textColor: 'white' },
  { title: 'Production', value: 'production', color: 'black', textColor: 'white' },
  { title: 'Archived', value: 'archived', color: 'grey', textColor: 'white' },
]

export const featureStates: StateItem[] = [
  { title: 'Proposed', value: 'proposed', color: '#e0e0e0', textColor: 'black' },
  { title: 'Published', value: 'published', color: 'green', textColor: 'white' },
  { title: 'deprecated', value: 'deprecated', color: 'red', textColor: 'white' },
]

export const workspaceStates: StateItem[] = [
  { title: 'Created', value: 'created', color: '#e0e0e0', textColor: 'black' },
  { title: 'Review', value: 'review', color: 'amber', textColor: 'white' },
  { title: 'Commented', value: 'commented', color: 'orange', textColor: 'white' },
  { title: 'Approved', value: 'approved', color: 'green', textColor: 'white' },
]

export const reviewStates: StateItem[] = [
  { title: 'Review', value: 'review', color: 'amber', textColor: 'white' },
  { title: 'Commented', value: 'commented', color: 'orange', textColor: 'white' },
]

export const insightExportPreviewLength = 5

export const insightExportTypes = ['Insight settings (CSV)', 'Insight messages (CSV)', 'All data (JSON)'] as const

export type InsightExportType = (typeof insightExportTypes)[number]
