import { Timestamp } from 'firebase/firestore'

export type WaltariProject = {
  id: string
  key?: string | { title: string; value: string }
  name: string
  team: string
  info?: string
  status?: string
  admins?: string[]
  editors?: string[]
  owner?: string | null
  updatedAt?: Timestamp
  createdAt?: Timestamp
  changesAt?: Timestamp
  releasedAt?: Timestamp
  state?: ProjectStateType
  sections: {
    content: boolean
    features: boolean
  }
}

export type ProjectStateItem = {
  value: ProjectStateType
  title: CapitalizedProjectStateType<ProjectStateType>
  color: string
  textColor: string
}

export type ProjectStateType = 'active' | 'blocked' | 'completed' | ''
export type CapitalizedProjectStateType<ProjectStateType extends string> =
  ProjectStateType extends `${infer First}${infer Rest}` ? `${Uppercase<First>}${Rest}` : never

export enum ProjectTabs {
  OWN = 'Own',
  ALL = 'All',
  PINNED = 'Pinned',
  UNRELEASED = 'Unreleased',
}

export type CategorizedProjects = {
  alphabetical: { [key: string]: WaltariProject[] }
  pinned: { [key: string]: WaltariProject[] }
}
