import { chunk, sortBy, uniq } from 'lodash-es'

import { Pinia, Store } from 'pinia-class-component'

import { LabelUser, UserLabel } from '#types'

@Store
export class LabelsStore extends Pinia {
  public loading = false
  public waiting = false

  public users: LabelUser[] = []
  public labels: UserLabel[] = []
  public pagination = {
    pageSize: 100,
    next: null,
    prev: null,
    total: 0,
  }

  public clearLabelUsers() {
    this.users = []

    this.pagination = { pageSize: 100, next: null, prev: null, total: 0 }
  }

  /*
   * Load user labels for current environment into the store
   */
  public async loadLabels(env: string) {
    this.labels = []

    this.loading = true

    const response = await this.$axios.get(`/api/v1/admin/labels`, { apiEnv: env })

    this.labels = response?.data?.labels || []

    this.loading = false
  }

  /*
   * Fetch and return label users from all environments
   */
  public async fetchLabelUsers(label: string) {
    this.waiting = true

    let users: any[] = []
    let pagination: any = { pageSize: 100, next: null, prev: null, total: 0 }

    const cloudEnv = import.meta.env.VITE_CLOUD_ENV
    const cloudEnvs = ['test', 'stage', 'prod'].splice(0, ['test', 'stage', 'prod'].indexOf(cloudEnv) + 1)

    for (const e of cloudEnvs.length ? cloudEnvs : [cloudEnv]) {
      try {
        const response: any = await this.$axios.get(`/api/v1/admin/labels/${label}/users?pageSize=10`, {
          id: 'fetchLabelUsers-' + e,
          apiEnv: e,
        })

        users = users.concat((response?.data?.users || []).map((u: any) => ({ ...u, env: e })))

        pagination = {
          pageSize: 100,
          next: null,
          prev: null,
          total: pagination.total + response?.data?.pagination?.total,
        }
      } catch (_error) {
        // Error can happen if label has not been created yet
      }
    }

    this.waiting = false

    return { users, pagination }
  }

  // List users from the given environment
  public async listEnvLabelUsers(env: string, data: any) {
    let apiPath = ''
    this.waiting = true

    let users: any[] = []
    let pagination: any = {}

    if (data?.search) {
      apiPath = `/api/v1/admin/labels/${data.label}/users/${data.search}`
    } else if (data?.path) {
      apiPath = import.meta.env.VITE_API_URL + data.path
    } else {
      apiPath = `/api/v1/admin/labels/${data.label}/users?pageSize=${data?.itemsPerPage || 10} `
    }

    const response = await this.$axios.get(apiPath, {
      apiEnv: env,
    })

    if (data?.search) {
      users = response?.data ? [response.data] : []

      pagination = { pageSize: data?.itemsPerPage ?? 100, next: null, prev: null, total: response?.data ? 1 : 0 }
    } else {
      users = sortBy(response?.data?.users || [], (o) => o.createdAt).reverse()

      pagination = response?.data?.pagination ?? { pageSize: 100, next: null, prev: null, total: 0 }
    }

    this.users = data.page > 1 ? (this.users = this.users.concat(users)) : users

    this.pagination = response?.data?.pagination ?? { pageSize: 100, next: null, prev: null, total: 0 }

    this.waiting = false

    return { users, pagination }
  }

  public async createUserLabel(env: string, label: string) {
    this.loading = true

    const labelRes = await this.$axios.post(`/api/v1/admin/labels`, { name: label }, { apiEnv: env })

    this.loadLabels(env)

    this.loading = false

    return labelRes
  }

  public async addUsersToLabel(env: string, data: { label: string; userUids: string[] }) {
    const { label, userUids } = data

    let allUsers: any[] = []

    this.loading = true

    const labelRes = await this.$axios.get(`/api/v1/admin/labels/${label}`, { apiEnv: env })

    if (labelRes?.status === 404) {
      await this.createUserLabel(env, label)
    }

    for (const c of chunk(uniq(userUids), 1000)) {
      const usersRes = await this.$axios.post(`api/v1/admin/labels/${label}/users`, { userUids: c }, { apiEnv: env })

      if (usersRes?.status === 200) {
        allUsers = allUsers.concat(usersRes.data.users || [])
      }
    }

    /*
    for (const c of chunk(uniq(userUids), 10000)) {
      const results = await Promise.all(
        chunk(c, 1000).map((pc) =>
          this.$axios.post(`api/v1/admin/labels/${label}/users`, { userUids: pc }, { apiEnv: env }),
        ),
      )

      for (const r of results) {
        if (r?.status === 200) {
          allUsers = allUsers.concat(r.data.users || [])
        }
      }
    }
    */

    this.listEnvLabelUsers(env, { label })

    this.loading = false

    return allUsers
  }

  public async removeUsersFromLabel(env: string, label: string, userUids: string[]) {
    this.loading = true

    for (const userUid of uniq(userUids)) {
      await this.$axios.delete(`/api/v1/admin/labels/${label}/users/${userUid}`, { apiEnv: env })
    }

    this.listEnvLabelUsers(env, { label })

    this.loading = false
  }

  /*
   * Convert emails to uids and check that the accounts exists
   */
  public async convertEmailsToUids(env: string, emails: string[]) {
    this.waiting = true

    let uids: string[] = []

    for (const c of chunk(uniq(emails), 100)) {
      const response = await this.$axios.post(`api/v1/admin/user-email-search`, { emails: c }, { apiEnv: env })

      uids = uids.concat(response.data?.userUids || [])
    }

    this.waiting = false

    return uids || []
  }
}
