<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-app-bar :title="'Feature Flags / App Features (' + filteredFeatures.length + ')'">
    <v-spacer />

    <v-text-field
      v-model="routeParams.searchText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      placeholder="Type to filter features..."
      @click:clear="routeParams.searchText = ''"
    />

    <v-menu offset="8" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          icon="mdi-filter-variant-plus"
          :color="props.value ? 'primary' : ''"
          :active="routeParams.projectFilter === 'true' || showDeprecatedFlags"
        />
      </template>

      <v-list>
        <v-list-subheader>Listing filtering options</v-list-subheader>

        <v-list-item>
          <v-switch
            label="Filter by selected project"
            style="white-space: nowrap"
            :model-value="routeParams.projectFilter === 'true'"
            @update:model-value="routeParams.projectFilter = $event ? 'true' : 'false'"
          />
        </v-list-item>

        <v-list-item>
          <v-switch
            label="Only show deprecated flags"
            style="white-space: nowrap"
            :model-value="showDeprecatedFlags"
            @update:model-value="routeParams.deprecatedFlags = $event ? 'true' : 'false'"
          />
        </v-list-item>
      </v-list>
    </v-menu>

    <v-tooltip location="bottom" text="Feature flag rollouts calendar view">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-calendar" @click="routeParams.calendarOpen = 'true'" />
      </template>
    </v-tooltip>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">Feature flags allow safe release of new app features</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="hasAppFeatureAdminRights">
            Your feature admin rights allow you to create and edit feature flags
          </template>
          <template v-else>You can only view feature flags since you are missing feature admin rights</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          color="primary"
          text="Create Feature"
          :disabled="!hasAppFeatureAdminRights || !!(activeProject && !activeProject?.sections?.features)"
          @click="openNewPanel()"
        />
      </v-col>
    </v-row>

    <v-alert v-if="errorMessage" closable type="error" class="my-4" @click:close="errorMessage = null">
      <v-row align="center">
        <v-col class="grow">
          {{ errorMessage }}
        </v-col>
      </v-row>
    </v-alert>

    <v-row v-if="activeProject && !activeProject?.sections?.features">
      <v-col class="d-flex justify-center" cols="12">
        <v-divider />

        <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
          Selected project does not use feature flags, you can select another project from the top bar or unselect the
          current project to see all the existing feature flags.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-alert
          v-if="showDeprecatedFlags"
          closable
          type="info"
          class="mb-4"
          @click:close="routeParams.deprecatedFlags = ''"
        >
          Only show deprecated feature flags filtering is on, close this banner to remove the filtering
        </v-alert>

        <v-sheet>
          <v-data-table
            show-expand
            single-expand
            expand-on-click
            disable-pagination
            hide-default-footer
            class="table-flags"
            :loading="isLoading"
            :expanded="expandedRow"
            :items-per-page="1000"
            :items="filteredFeatures"
            :headers="listHeadersWithSortFunctions"
            :no-data-text="
              (routeParams.searchText ? 'No matching feature flags' : 'No existing feature flags') +
              (activeProject ? ' in selected project' : '')
            "
            @update:expanded="
              ((expandedRow = $event.splice(-1)),
              $router.push(expandedRow[0] ? `/features/${expandedRow[0]}` : '/features'))
            "
          >
            <template #item.metadata.name="{ item }">
              <v-hover>
                <template #default="{ isHovering, props: hoverProps }">
                  <div v-bind="hoverProps" class="d-flex flex-row text-no-wrap">
                    <v-tooltip :text="item.id" location="top start">
                      <template #activator="{ props: tooltipProps }">
                        <span v-bind="tooltipProps" class="text-truncate text-no-wrap font-weight-light">
                          {{ item.id.toUpperCase() }}
                        </span>
                      </template>
                    </v-tooltip>

                    <v-btn
                      size="small"
                      icon="mdi-content-copy"
                      class="my-n2 ml-2 mr-n4"
                      :style="'visibility: ' + (isHovering ? 'visible' : 'hidden')"
                      @click.stop="useClipboard.copy(item.id)"
                    />
                  </div>
                </template>
              </v-hover>
            </template>

            <template #item.project="{ item }">
              <span class="text-truncate text-no-wrap font-weight-light">
                {{ getProjectName(item) }}
              </span>
            </template>

            <template #item.parameters="{ item }">
              <div class="text-truncate text-no-wrap font-weight-light">
                {{ item.parameters.length }}
                parameter{{ item.parameters.length === 1 ? '' : 's' }}
              </div>
            </template>

            <template #item.metadata="{ item }">
              <div style="overflow: hidden">
                <StatisticsChip
                  v-if="typeof enabledForEnvironment(item) === 'number'"
                  disabled
                  textonly
                  unit="users"
                  stat="count"
                  class="mt-n2 mr-n6"
                  :loading="isLoading"
                  :stats-data="featureAmplitudeStats(item)"
                />

                <div v-else class="text-no-wrap font-weight-light" style="overflow: hidden; text-overflow: ellipsis">
                  {{ enabledForEnvironment(item) }}
                </div>
              </div>
            </template>

            <template #item.metadata.changeRecord="{ item }">
              <div class="font-weight-light">
                <span class="text-no-wrap">
                  {{
                    $dayjs(
                      item.metadata?.changeRecord?.lastModifiedAt || item.metadata?.changeRecord?.createdAt,
                    ).format('HH:mm')
                  }}
                </span>
                <span class="text-no-wrap">
                  -
                  {{
                    $dayjs(
                      item.metadata?.changeRecord?.lastModifiedAt || item.metadata?.changeRecord?.createdAt,
                    ).format('DD MMM YYYY')
                  }}
                </span>
              </div>
            </template>

            <!-- <template #item.featureState>
            <StateChip class="mt-n1 mr-0" :readonly="!hasAppFeatureAdminRights" :state-key="'published'" />
          </template> -->

            <template #expanded-row="{ columns, item }">
              <td class="expanded-row" :colspan="columns.length">
                <FeatureDrawer
                  :feature="item"
                  @edit="openEditPanel($event)"
                  @notes="openNotesDialog($event)"
                  @rollouts="gotoRolloutsPage($event)"
                />
              </td>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <FeaturesState v-model:route-params="routeParams" />
  </v-container>

  <FeaturePanel :feature="selectedFeature" />

  <NotesDialog ref="notesDialogRef" />
</template>

<script lang="ts">
  import { cloneDeep } from 'lodash-es'

  import { UseClipboardReturn, useClipboard } from '@vueuse/core'

  import { Component, Prop, Ref, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { featuresHeaders } from '#views/features/constants'

  import { createFeature, isFeatureEnabled } from '#views/features/utilities'
  import { getProjectKey, getProjectName, getProjectTeam } from '#views/projects/utilities'

  import { AppStore, FeaturesStore, ProjectsStore } from '#stores'

  import { Dialog, Feature } from '#types'

  @Component({})
  class FeaturesView extends mixins(RouteParams) {
    @Prop() public featureId!: string
    @Prop() public projectId!: string

    public routeParams: any = {
      searchText: '',
      projectFilter: 'true',
      deprecatedFlags: '',
      calendarOpen: 'false',
      productionOnly: 'true',
      rolloutSearch: '',
      minAppVersion: '',
    }

    public expandedRow: string[] = []

    public selectedFeature: Feature | null = null

    public readonly featuresHeaders = featuresHeaders

    public readonly getProjectKey = getProjectKey
    public readonly getProjectName = getProjectName
    public readonly isFeatureEnabled = isFeatureEnabled

    @Setup(() => useClipboard())
    public readonly useClipboard!: UseClipboardReturn<false>

    private readonly appStore = new AppStore()

    private readonly projectsStore = new ProjectsStore()
    private readonly featuresStore = new FeaturesStore()

    @Ref() private readonly notesDialogRef: Dialog | null = null

    public get isLoading() {
      return this.showDeprecatedFlags ? this.featuresStore.loadingDeprecated : this.featuresStore.loadingActive
    }

    public get featureStats() {
      return this.featuresStore.stats
    }

    public get errorMessage() {
      return this.featuresStore.errorMessage
    }

    public set errorMessage(msg: string | null) {
      this.featuresStore.errorMessage = msg
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get showDeprecatedFlags() {
      return this.routeParams.deprecatedFlags === 'true'
    }

    public get existingFeatures() {
      return this.featuresStore.features.filter((f) => f.deprecations.length > 0 === this.showDeprecatedFlags)
    }

    public get filteredFeatures() {
      const search = this.routeParams.searchText.toLowerCase()

      return this.existingFeatures
        .map((f) => ({ ...f, id: `${getProjectKey(f)}/${f.metadata?.name}` }))
        .filter(
          (f: any) =>
            (!this.projectsStore.project ||
              this.routeParams.projectFilter === 'false' ||
              f.metadata.informative.labels.project === this.projectsStore.project.id) &&
            (!this.routeParams.searchText ||
              f.metadata.name.toLowerCase().includes(search) ||
              getProjectName(f)?.toLowerCase()?.includes(search) ||
              getProjectTeam(f)?.toLowerCase()?.includes(search) ||
              f.metadata?.informative?.changeRecord?.createdBy?.toLowerCase()?.includes(search) ||
              f.metadata?.informative?.additionalData?.createdBy?.toLowerCase()?.includes(search)),
        )
    }

    public get hasAppFeatureAdminRights() {
      return this.appStore.isAppFeatureAdmin
    }

    public get listHeadersWithSortFunctions() {
      const envOrder: any = [
        'No rollouts',
        'Not enabled',
        'Sandbox apps',
        'Experimental app',
        'Staging app',
        'Ouranians only',
      ]

      const headers = cloneDeep(featuresHeaders)

      headers.find((h: any) => h.key === 'metadata').sortRaw = (a: any, b: any) => {
        const aV = this.enabledForEnvironment(a)
        const bV = this.enabledForEnvironment(b)

        return (
          (envOrder.includes(aV) ? envOrder.indexOf(aV) : envOrder.length + aV) -
          (envOrder.includes(bV) ? envOrder.indexOf(bV) : envOrder.length + bV)
        )
      }

      headers.find((h: any) => h.key === 'metadata.changeRecord').sort = (a: any, b: any) =>
        this.$dayjs(a.lastModifiedAt || a.createdAt).valueOf() - this.$dayjs(b.lastModifiedAt || b.createdAt).valueOf()

      return headers
    }

    @Watch('featureId')
    protected featureIdChanged() {
      this.appStore.closeNavDrawer('feature')
    }

    @Watch('projectId')
    protected projectIdChanged() {
      this.appStore.closeNavDrawer('feature')
    }

    @Watch('isLoading', { immediate: true })
    protected isLoadingChanged() {
      if (!this.isLoading && this.featureId && this.projectId) {
        const selectedItem = this.filteredFeatures.find(
          (item) => item.metadata?.name === this.featureId && getProjectKey(item) === this.projectId,
        )

        if (selectedItem) {
          this.expandedRow = [selectedItem.id]
        }
      }
    }

    public async openNewPanel() {
      this.selectedFeature = createFeature()

      this.appStore.toggleNavDrawer('feature')
    }

    public openEditPanel(feature: Feature) {
      this.selectedFeature = feature

      this.appStore.toggleNavDrawer('feature')
    }

    public openNotesDialog(feature: Feature) {
      this.notesDialogRef!.open({
        noteObject: { ...feature, id: feature.metadata?.name },
        collectionPath: '/features',
      })
    }

    public gotoRolloutsPage(feature: Feature) {
      this.$router.push(`/features/${getProjectKey(feature)}/${feature.metadata?.name}/rollouts`)
    }

    public featureAmplitudeStats(feature: Feature) {
      if (this.featuresStore.stats?.seriesLabels) {
        const name = `${getProjectKey(feature).toLowerCase()}/${feature.metadata?.name}`

        const index = this.featuresStore.stats?.seriesLabels.findIndex((feature: any) => feature[1] === name)

        if (index > -1) {
          const seriesForId = this.featuresStore.stats?.series[index]

          return { xValues: this.featuresStore.stats?.xValues, series: seriesForId }
        }
      }

      return null
    }

    public enabledForEnvironment(feature: Feature) {
      const stats: any = this.featureAmplitudeStats(feature)

      const count = stats?.series ? Math.max(...stats.series) : 0

      if (
        count > 0 ||
        isFeatureEnabled(feature, 'release') ||
        isFeatureEnabled(feature, 'release-ios') ||
        isFeatureEnabled(feature, 'release-android') ||
        (stats?.series ? Math.max(...stats.series) : 0) > 0
      ) {
        return count
      } else if (isFeatureEnabled(feature, 'ouranians')) {
        return 'Ouranians only'
      } else if (isFeatureEnabled(feature, 'staging')) {
        return 'Staging app'
      } else if (isFeatureEnabled(feature, 'experimental')) {
        return 'Experimental app'
      } else if (isFeatureEnabled(feature, 'sandbox')) {
        return 'Sandbox apps'
      } else if (!feature.overrideList.length) {
        return 'No rollouts'
      } else {
        return 'Not enabled'
      }
    }
  }

  export default toNative(FeaturesView)
</script>

<style lang="scss" scoped>
  :deep(.v-data-table) {
    .v-table__wrapper {
      & > table {
        table-layout: initial !important;

        &,
        & > thead,
        & > tbody {
          & > tr {
            th,
            td {
              float: left;
              padding: 15px 16px;

              &:first-child {
                width: 48px;
                padding: 6px !important;
              }

              &:nth-child(2) {
                width: calc(30% - 28px);
              }

              &:nth-child(3) {
                width: calc(25% - 30px);
              }

              &:nth-child(4) {
                width: calc(20% - 30px);
              }

              &:nth-child(5) {
                width: calc(15% - 30px);
              }

              &:nth-child(6) {
                width: calc(10% + 70px);
              }
            }

            &.v-data-table-progress {
              th {
                width: 100% !important;
              }
            }

            &.v-data-table-rows-no-data,
            &.v-data-table-rows-loading {
              td {
                width: 100% !important;
                padding: 12px !important;
              }
            }
          }
        }

        .expanded-row {
          .v-table__wrapper {
            & > table {
              & > tr {
                td {
                  &:nth-of-type(4) {
                    width: calc(35% - 60px);
                  }

                  &:nth-child(5) {
                    width: calc(10% + 68px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
