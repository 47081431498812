import { algoliasearch } from 'algoliasearch'

import { Pinia, Store } from 'pinia-class-component'

import { AppStore } from '#stores'

// TODO: move this to a plugin
const algolia = algoliasearch(import.meta.env.VITE_ALGOLIA_APP_ID!, import.meta.env.VITE_ALGOLIA_SEARCH_KEY!)

@Store
export class SearchStore extends Pinia {
  public loading = false

  public results: any[] = []

  public async updateSearch(search: string) {
    this.loading = true

    const appStore = new AppStore()

    // TODO: We should handle paging, we already have more than 1000 insights so this might not return all results

    if (!search || search.length < 3) {
      this.results = []
    } else {
      const options: any = {
        searchParams: {
          query: search,
          page: 0,
          facets: [],
          distinct: 1,
          analytics: false,
          hitsPerPage: 1000,
          getRankingInfo: false,
          attributesToRetrieve: ['insightId'],
        },
        indexName: appStore.inWorkspace || appStore.inApprovals ? 'workspace' : 'insights',
      }

      if (appStore.inWorkspace) {
        options.filters = `userId:'${appStore.user?.uid}'`
      }

      if (search.includes(':') || search.includes('_')) {
        options.typoTolerance = false
      }

      const [key, value] = search.split(':')

      if (key && value) {
        options.query = value
        options.restrictSearchableAttributes = [key]
      }

      const results = await algolia.searchSingleIndex(options)

      this.results = results.hits.map((r: any) => r.insightId)
    }

    this.loading = false
  }
}
