<template>
  <!-- eslint-disable vue/v-on-handler-style -->

  <v-app-bar :title="'User Segments / App Users (' + filteredCriterias.length + ')'">
    <v-spacer />

    <v-text-field
      v-model="routeParams.searchText"
      clearable
      class="mr-2"
      style="max-width: 500px"
      prepend-icon="mdi-magnify"
      placeholder="Type to filter segments..."
      @click:clear="routeParams.searchText = ''"
    />

    <v-menu offset="8" max-height="500" :close-on-content-click="false">
      <template #activator="{ props }">
        <v-btn v-bind="props" icon="mdi-filter-variant-plus" :color="props.value ? 'primary' : ''" />
      </template>

      <v-list>
        <v-list-subheader>Select additional filters</v-list-subheader>

        <v-list-item>
          <v-switch
            label="Filter by selected project"
            style="white-space: nowrap"
            :model-value="routeParams.projectFilter === 'true'"
            @update:model-value="routeParams.projectFilter = $event ? 'true' : 'false'"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-container>
    <v-row>
      <v-col cols="12" md="9">
        <div class="text-h5 font-weight-light">User segments can be used in feature rollouts</div>

        <div class="text-subtitle-2 text-medium-emphasis font-weight-light">
          <template v-if="hasAppFeatureAdminRights">
            Your feature admin rights allow you to create and edit segments
          </template>
          <template v-else>You can only view segments since you are missing feature admin rights</template>
        </div>
      </v-col>

      <v-col md="3" cols="12" class="text-right">
        <v-btn
          color="primary"
          text="Create segment"
          :disabled="!hasAppFeatureAdminRights || !!(activeProject && !activeProject?.sections?.features)"
          @click="openNewPanel()"
        />
      </v-col>
    </v-row>

    <v-alert v-if="!!errorMessage" closable type="error" class="my-4" @click:close="errorMessage = null">
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="activeProject && !activeProject?.sections?.features">
      <v-col class="d-flex justify-center" cols="12">
        <v-divider />

        <v-alert rounded type="info" class="pa-8 opacity-80" position="absolute" width="50%" style="top: 45%">
          Selected project does not use user segments, you can select another project from the top bar or unselect the
          current project to see all the existing user segments.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <v-sheet>
          <v-data-table
            show-expand
            single-expand
            expand-on-click
            disable-pagination
            hide-default-footer
            item-value="metadata.name"
            :loading="isLoading"
            :expanded="expandedRow"
            :headers="listHeadersWithSortFunctions"
            :items-per-page="1000"
            :items="filteredCriterias"
            :no-data-text="routeParams.searchText ? 'No matching segments' : 'No existing segments'"
            @update:expanded="
              ((expandedRow = $event.splice(-1)),
              $router.push(expandedRow[0] ? `/segments/${expandedRow[0]}` : '/segments'))
            "
          >
            <template #item.metadata.name="{ item }">
              <v-hover>
                <template #default="{ isHovering, props: hoverProps }">
                  <div v-bind="hoverProps" class="d-flex flex-row text-no-wrap">
                    <v-tooltip :text="item.metadata?.name" location="top start">
                      <template #activator="{ props: tooltipProps }">
                        <span v-bind="tooltipProps" class="text-truncate text-no-wrap font-weight-light">
                          {{ item.metadata?.name?.toUpperCase() }}
                        </span>
                      </template>
                    </v-tooltip>

                    <v-btn
                      size="small"
                      icon="mdi-content-copy"
                      class="my-n2 ml-2 mr-n4"
                      :style="'visibility: ' + (isHovering ? 'visible' : 'hidden')"
                      @click.stop="useClipboard.copy(item.metadata?.name || '')"
                    />
                  </div>
                </template>
              </v-hover>
            </template>

            <template #item.expression="{ item }">
              <span class="font-weight-light">
                {{ getExpressionsCount(item) }}
              </span>
            </template>

            <template #item.metadata="{ item }">
              <div class="text-truncate text-no-wrap font-weight-light">
                {{ criteriaOverrides[item.metadata!.name]?.length || 0 }}
                rollout{{ criteriaOverrides[item.metadata!.name]?.length === 1 ? '' : 's' }}
              </div>
            </template>

            <template #item.metadata.informative.labels.color="{ item }">
              <div class="text-right">
                <v-tooltip top>
                  <template #activator="{ props }">
                    <span
                      v-bind="props"
                      class="criteria-color"
                      :class="'bg-' + item.metadata?.informative?.labels.color + '-lighten-1'"
                    />
                  </template>
                  <span>
                    {{ segmentCategories.find((c) => c.value === item.metadata?.informative?.labels?.color)?.title }}
                  </span>
                </v-tooltip>
              </div>
            </template>

            <template #item.project="{ item }">
              <div class="text-truncate text-no-wrap font-weight-light">
                {{ getProjectName(item) }}
              </div>
            </template>
            <template #item.expression.type="{ item }">
              <div class="text-truncate text-no-wrap font-weight-light">
                {{
                  item.expression?.oneOf?.$case === 'or' && item.expression?.oneOf.or
                    ? 'Custom predicates'
                    : 'User group label'
                }}
              </div>
            </template>

            <template #expanded-row="{ columns, item }">
              <td class="expanded-row" :colspan="columns.length">
                <CriteriaDrawer
                  :criteria="previewCriteria?.metadata?.name === item.metadata?.name ? previewCriteria : item"
                  :criterias="existingCriterias"
                  :overrides="criteriaOverrides"
                  @edit="openEditPanel($event)"
                  @notes="openNotesDialog($event)"
                />
              </td>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>

  <SegmentPanel
    :criteria="selectedCriteria"
    :features="existingFeatures"
    :criterias="existingCriterias"
    :overrides="criteriaOverrides"
    @close="previewCriteria = null"
    @change="previewCriteria = $event"
    @modify="selectedCriteria = $event"
  />

  <NotesDialog ref="notesDialogRef" />
</template>

<script lang="ts">
  import { cloneDeep } from 'lodash-es'

  import { UseClipboardReturn, useClipboard } from '@vueuse/core'

  import { Component, Prop, Ref, Setup, Watch, mixins, toNative } from 'vue-facing-decorator'

  import { RouteParams } from '@jouzen/outo-toolkit-vuetify'

  import { segmentCategories, segmentHeaders } from '#views/segments/constants'

  import { forEachFeatureOverride } from '#views/features/utilities'
  import { getProjectKey, getProjectName } from '#views/projects/utilities'
  import { createDefaultCriteria, forEachCriteriaPredicates, getExpressionsCount } from '#views/segments/utilities'

  import { AppStore, FeaturesStore, ProjectsStore, SegmentsStore } from '#stores'

  import { Criteria, Dialog } from '#types'

  @Component({})
  class SegmentsView extends mixins(RouteParams) {
    @Prop() public segmentId!: string

    public routeParams: any = {
      searchText: '',
      projectFilter: 'false',
    }

    public expandedRow: string[] = []

    public criteriaOverrides: any = {}

    public previewCriteria: Criteria | null = null
    public selectedCriteria: Criteria | null = null

    public readonly getProjectKey = getProjectKey
    public readonly getProjectName = getProjectName

    public readonly segmentCategories = segmentCategories

    @Ref() public readonly notesDialogRef!: Dialog

    @Setup(() => useClipboard())
    public readonly useClipboard!: UseClipboardReturn<false>

    private readonly appStore = new AppStore()
    private readonly projectsStore = new ProjectsStore()
    private readonly featuresStore = new FeaturesStore()
    private readonly segmentsStore = new SegmentsStore()

    public get isLoading() {
      return this.segmentsStore.loading
    }

    public get errorMessage() {
      return this.segmentsStore.errorMessage
    }

    public set errorMessage(msg: string | null) {
      this.segmentsStore.errorMessage = msg
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public get existingFeatures() {
      return this.featuresStore.features
    }

    public get existingCriterias() {
      return this.segmentsStore.criterias
    }

    public get filteredCriterias() {
      return this.existingCriterias.filter(
        (i: any) =>
          (!this.routeParams.searchText || i.metadata?.name?.includes(this.routeParams.searchText.toLowerCase())) &&
          (this.routeParams.projectFilter !== 'true' ||
            !this.activeProject ||
            i.metadata.informative.labels.project === this.activeProject?.id),
      )
    }

    public get hasAppFeatureAdminRights() {
      return this.appStore.isAppFeatureAdmin
    }

    public get listHeadersWithSortFunctions() {
      const headers = cloneDeep(segmentHeaders)

      headers.find((h: any) => h.key === 'expression').sort = (a: any, b: any) =>
        getExpressionsCount(a) - getExpressionsCount(b)

      headers.find((h: any) => h.key === 'metadata').sort = (a: any, b: any) =>
        (this.criteriaOverrides[a.name]?.length || 0) - (this.criteriaOverrides[b.name]?.length || 0)

      headers.find((h: any) => h.key === 'metadata.informative.labels.color').sort = (a: any, b: any) =>
        (segmentCategories.find((c: any) => c.value === a)?.title || '').localeCompare(
          segmentCategories.find((c: any) => c.value === b)?.title || '',
        )

      return headers
    }

    @Watch('isLoading', { immediate: true })
    protected isLoadingChanged() {
      if (!this.isLoading && this.segmentId) {
        const selectedItem = this.filteredCriterias.find((item: any) => item.metadata.name === this.segmentId)

        if (selectedItem) {
          this.expandedRow = [selectedItem.metadata!.name]
        }
      }
    }

    @Watch('existingFeatures', { immediate: true })
    protected existingFeaturesChanged() {
      this.criteriaOverrides = {}

      this.existingFeatures.forEach((feature) => {
        forEachFeatureOverride(feature, (override) => {
          forEachCriteriaPredicates(override.criteria, (predicate) => {
            if (predicate.oneOf.$case === 'generic' && predicate.oneOf.generic.oneOf.$case === 'namedCriteria') {
              const criteriaName = predicate.oneOf.generic.oneOf.namedCriteria.name

              this.criteriaOverrides[criteriaName] ??= []
              this.criteriaOverrides[criteriaName].push({ feature, override })
            }

            if (
              predicate.oneOf.$case === 'user' &&
              predicate.oneOf.user.oneOf.$case === 'hasLabelThat' &&
              predicate.oneOf.user.oneOf.hasLabelThat.oneOf.$case === 'equals' &&
              predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals.length
            ) {
              const predicateLabel = predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals

              if (predicateLabel.startsWith('criteria:')) {
                this.criteriaOverrides[predicateLabel] ??= []
                this.criteriaOverrides[predicateLabel].push({ feature, override })
              }
            }
          })
        })
      })
    }

    public openNewPanel() {
      this.selectedCriteria = createDefaultCriteria('user-labels')

      this.appStore.openNavDrawer('segment')
    }

    public openEditPanel(criteria: Criteria) {
      this.selectedCriteria = criteria

      this.appStore.openNavDrawer('segment')
    }

    public openNotesDialog(criteria: Criteria) {
      this.notesDialogRef.open({
        noteObject: { id: criteria.metadata?.name, ...criteria },
        collectionPath: '/segments',
      })
    }

    public getExpressionsCount(criteria: Criteria) {
      const count = getExpressionsCount(criteria.expression!)

      return `${count} expression${count == 1 ? '' : 's'}`
    }
  }

  export default toNative(SegmentsView)
</script>

<style lang="scss" scoped>
  :deep(.criteria-color) {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #000;
    border-radius: 4px;
    opacity: 0.8;
  }

  :deep(.v-data-table) {
    .v-table__wrapper {
      & > table {
        table-layout: initial !important;

        &,
        & > thead,
        & > tbody {
          & > tr {
            th,
            td {
              float: left;
              padding: 15px 16px;
              width: calc(25% - 40px);

              &:nth-child(1) {
                width: 48px;
                padding: 6px !important;
              }

              &:nth-child(2) {
                width: calc(25% - 24px);
              }

              &:nth-child(6) {
                width: calc(96px);
              }
            }

            &.v-data-table-progress {
              th {
                width: 100% !important;
              }
            }

            &.v-data-table-rows-no-data,
            &.v-data-table-rows-loading {
              td {
                width: 100% !important;
                padding: 12px !important;
              }
            }
          }

          .expanded-row {
            .v-table__wrapper {
              & > table {
                & > tr {
                  td {
                    &:nth-child(3) {
                      width: calc(50% - 80px);
                    }

                    &:nth-child(4) {
                      width: calc(25% + 56px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
