import { validate, version } from 'uuid'

import { Expression } from '@jouzen/feature-mgmt-api/criteria'
import { Context, Project_StaticId, TopLevel_ObjectType } from '@jouzen/feature-mgmt-api/metadata'

import { Criteria } from '#types'

export function validateUserUid(uuid: string) {
  return (validate(uuid) && version(uuid) === 4) || 'Not in uuid format'
}

export function validateUserUids(uuids: string[]): string | true {
  const invalid = uuids.find((uuid: string) => !validate(uuid) || version(uuid) !== 4)

  return invalid ? `Not a valid uuid: ${invalid}` : true
}

export function getPredicateData(expression: Expression) {
  return (
    (expression.oneOf?.$case === 'predicate' && expression.oneOf.predicate.oneOf) ||
    (expression.oneOf?.$case === 'not' &&
      expression.oneOf?.not?.expression?.oneOf?.$case === 'predicate' &&
      expression.oneOf.not.expression.oneOf.predicate.oneOf) ||
    null
  )
}

export function getExpressionsCount(expression: Expression) {
  if (!expression?.oneOf?.$case) {
    return 0
  } else if (expression.oneOf.$case === 'predicate') {
    return 1
  } else if (expression.oneOf.$case === 'or' && expression.oneOf?.or?.expressions) {
    const count = expression.oneOf.or.expressions.reduce(
      (total: number, e: any) => total + e.oneOf.and.expressions.length,
      0,
    )

    return count
  } else {
    return 0
  }
}

export function forEachCriteriaPredicates(expression: Expression | undefined, callback: (predicate: any) => void) {
  function checkExpression(e: Expression | undefined) {
    if (e?.oneOf?.$case === 'predicate') {
      callback(e.oneOf.predicate)
    } else if (e?.oneOf?.$case === 'not') {
      checkExpression(e.oneOf.not.expression)
    } else if (e?.oneOf?.$case === 'or') {
      const expressions = e?.oneOf.or.expressions

      expressions.forEach((ee) => checkExpression(ee))
    } else if (e?.oneOf?.$case === 'and') {
      const expressions = e?.oneOf.and.expressions

      expressions.forEach((ee) => checkExpression(ee))
    }
  }

  checkExpression(expression)
}

export function createDefaultCriteria(type?: string): Criteria {
  return {
    metadata: {
      name: '',
      project: {
        oneOf: {
          $case: 'apiStatic',
          apiStatic: Project_StaticId.UNKNOWN,
        },
      },
      informative: {
        labels: {
          color: 'blue',
          project: '',
        },
        displayName: '',
        description: '',
        referenceUrls: ['https://ouraring.atlassian.net/wiki/spaces/IS/pages/4027318368/Feature+Flags+Criteria+Usage'],
        additionalData: {},
      },
      contextSpec: {
        oneOf: {
          $case: 'contexts',
          contexts: {
            contexts: [Context.OURA_USER],
          },
        },
      },
      changeRecord: {},
      accessControl: [],
      objectType: TopLevel_ObjectType.NAMED_CRITERIA,
    },
    expression:
      type === 'user-labels'
        ? {
            oneOf: {
              $case: 'predicate',
              predicate: {
                oneOf: {
                  $case: 'user',
                  user: {
                    oneOf: {
                      $case: 'hasLabelThat',
                      hasLabelThat: {
                        oneOf: {
                          $case: 'equals',
                          equals: '',
                        },
                      },
                    },
                  },
                },
              },
            },
          }
        : {
            oneOf: {
              $case: 'or',
              or: {
                expressions: [],
              },
            },
          },
  }
}
