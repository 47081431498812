<template>
  <v-dialog persistent width="800" :model-value="true">
    <v-card>
      <v-card-title>Confirm release creation</v-card-title>

      <v-card-text class="pt-2">
        <div v-if="loading" class="mb-4">Preparing content for the release, this should not take very long...</div>

        <v-progress-linear v-if="loading" indeterminate />

        <v-alert
          v-if="!loading && !!errorTabs.length"
          color="warning"
          text="There were some errors with the content preparation. You need to fix the errors before you can make a release."
        />

        <v-alert v-if="!loading && !errorTabs.length" color="info">
          Content is now ready for release, everything looks good! Clicking
          <b>"RELEASE"</b>
          will create an experimental release and activate all new media files.
        </v-alert>

        <v-tabs v-if="!loading && !!errorTabs.length" v-model="tab">
          <v-tab v-for="tab of errorTabs" :key="tab.type">{{ tab.type.toUpperCase() }}</v-tab>
        </v-tabs>

        <v-window v-if="!loading && !!errorTabs.length" v-model="tab" class="pa-4" style="min-height: 100px">
          <v-window-item v-for="tab of errorTabs" :key="tab.type">
            <ul>
              <li v-for="condition of tab.conditions" :key="condition.id">
                {{ condition.id.toUpperCase() }} condition has errors
              </li>
            </ul>

            <ul>
              <li v-for="translation of tab.translations" :key="translation.id">
                {{ translation.id.toUpperCase() }} has missing translations
              </li>
            </ul>
          </v-window-item>
        </v-window>
      </v-card-text>

      <v-card-actions style="overflow: hidden">
        <v-spacer />

        <v-btn text="Cancel" :disabled="loading || releasing" @click="cancel()" />
        <v-btn
          text="Release"
          color="primary"
          :loading="releasing"
          :disabled="loading || !!errorTabs.length"
          @click="confirmRelease()"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { uniq } from 'lodash-es'

  import { Component, Emit, Prop, Vue, toNative } from 'vue-facing-decorator'

  import {
    ContentsStore,
    FormulasStore,
    InsightsStore,
    ProjectsStore,
    RolloutsStore,
    SlideshowsStore,
    TipthemesStore,
  } from '#stores'

  @Component({})
  class ReleaseDialog extends Vue {
    @Prop() public releaseContent!: any

    @Emit('cancel')
    public cancel() {
      return true
    }

    @Emit('released')
    public released() {
      return true
    }

    public tab = 0

    public loading = false

    public errorTabs: any[] = []

    public releaseData: any = {}

    public readonly env = import.meta.env.VITE_APP_ENV

    private readonly projectsStore = new ProjectsStore()

    private readonly rolloutsStore = new RolloutsStore()

    private readonly contentsStore = new ContentsStore()

    private readonly formulasStore = new FormulasStore()
    private readonly insightsStore = new InsightsStore()

    private readonly tipthemesStore = new TipthemesStore()
    private readonly slideshowsStore = new SlideshowsStore()

    public get releasing() {
      return this.rolloutsStore.saving
    }

    public get activeProject() {
      return this.projectsStore.project
    }

    public async mounted() {
      this.loading = true

      let ruleIds: string[] = []

      this.releaseData = {
        project: this.releaseContent.project,
        translations: [],
      }

      const releaseTypes = ['contents', 'insights', 'tipthemes', 'slideshows', 'mappings-spotlight-modes']

      for (const releaseType of releaseTypes) {
        let responseData: any

        const releaseIds: string[] = []

        for (const content of this.releaseContent.changes) {
          if (!releaseIds.includes(content.rid) && releaseType.startsWith(content.type)) {
            releaseIds.push(content.rid)
          } else if (
            content.type === 'translations' &&
            content.changes.find((c: any) => c.tags.includes(releaseType))
          ) {
            content.changes.forEach((c: any) => {
              if (c.tags.includes(releaseType)) {
                c.translations.forEach((a: any) => {
                  this.releaseData.translations.push({
                    id: c.localeKey,
                    type: releaseType,
                    message: a.message,
                    language: a.language,
                  })
                })
              }
            })
          }
        }

        if (releaseIds.length) {
          switch (releaseType) {
            case 'contents':
              responseData = await this.contentsStore.exportContents({ contentIds: releaseIds })
              break
            case 'insights':
              responseData = await this.insightsStore.exportInsights({
                ruleIds,
                insightIds: releaseIds,
                insightsProject: this.activeProject?.id,
              })
              break
            case 'tipthemes':
              responseData = await this.tipthemesStore.exportTipthemes({
                ruleIds,
                insightIds: releaseIds,
                insightsProject: this.activeProject?.id,
              })
              break
            case 'slideshows':
              responseData = await this.slideshowsStore.exportSlideshows({
                ruleIds,
                insightIds: releaseIds,
                insightsProject: this.activeProject?.id,
              })
              break
            case 'mappings-spotlight-modes':
              this.releaseData[releaseType] = await this.formulasStore.listSpotlightModes(releaseIds)
              break
          }

          if (responseData) {
            this.releaseData[releaseType] = {
              content: responseData[releaseType],
              locales: responseData.translations,
              relations: responseData.relations,
            }

            const conditions = responseData[releaseType].filter((t: any) => t.errors.length)

            // TODO: Language filtering is temporal until new languages are ready to go!
            const translations = responseData.translations.filter((t: any) => t.errors.length)

            if (conditions.length || translations.length) {
              this.errorTabs.push({
                type: releaseType,
                conditions: conditions.reduce((missing: any, t: any) => {
                  t.errors.forEach((e: any) => {
                    const obj = missing.find((m: any) => m.id === e.split(':')[0])

                    if (obj) {
                      obj.errors.push(e.split(':')[1])
                    } else {
                      missing.push({ id: e.split(':')[0], errors: e.split(':')[1] })
                    }
                  })

                  return missing
                }, []),
                translations: translations.reduce((missing: any, t: any) => {
                  t.errors.forEach((e: any) => {
                    const obj = missing.find((m: any) => m.id === e.split(':')[0])

                    if (obj) {
                      obj.languages.push(t.language)
                      obj.keys = uniq(obj.keys.concat(e.split(':')[1].split(', ')))
                    } else {
                      missing.push({
                        id: e.split(':')[0],
                        keys: [e.split(':')[1].split(', ')],
                        languages: [t.language],
                      })
                    }
                  })

                  return missing
                }, []),
              })
            }
          }
        }
      }

      this.loading = false
    }

    public async confirmRelease() {
      await this.rolloutsStore.updateReleaseData(this.releaseData)

      if (this.env === 'release') {
        await this.rolloutsStore.rolloutAndActivate()

        await this.rolloutsStore.experimentalOtaRollout({
          projects: this.releaseData.project,
        })
      }

      this.released()
    }
  }

  export default toNative(ReleaseDialog)
</script>
