import axios from 'axios'

import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

/**
 * Saturn-API Upload Request object (v1)
 *
 * Loosely copied (added Optional) from [Saturn Repository](https://github.com/jouzen/content-ingestion-service/blob/main/api/src/lib/saturn.ts)
 * until a types package is properly published.
 */
interface UploadRequestParametersV1 {
  /** MediaObject slug used to identify this file (note: not the Variant Slug, which has extra annotations) */
  slug: string
  /** File Extension (used to generate S3 Put parameters) */
  ext: string
  /** Filero Media Type (audio, image, video, etc) */
  mediaType: 'audio' | 'image' | 'video'
  /** Language of the new file. Use "zxx" for "all languages" */
  contentLanguage: string
  /** Thumbnail Settings. Required to automatically generate thumbnail */
  thumbnail?: Record<string, unknown>
  /** Info Object. Required to provide info object properties like "category" and "provider", etc */
  info?: Record<string, unknown>
  /** MediaVariant Info Object. Required to provide info object properties like "category" and "provider", etc */
  variantInfo?: Record<string, unknown>
}

/**
 * Required parameters to fetch a Saturn job status using [Get Job Status](https://github.com/jouzen/content-ingestion-service/blob/main/api/DOCUMENTATION.md#get-job-status)
 */
export interface GetJobStatusParams {
  jobId: string
}

@Store
export class UploadsStore extends Pinia {
  public uploading = false

  public async uploadMedia(data: any) {
    this.uploading = true

    const payload: UploadRequestParametersV1 = {
      slug: data.slug,
      info: data.info,
      ext: data.file.name.split('.').pop(),
      mediaType: data.file.type.split('/').shift(),
      contentLanguage: data.language || 'zxx',
    }

    if (data.thumbnail) {
      payload.thumbnail = data.thumbnail
    } else {
      if (payload.mediaType === 'image' && data.variantInfo) {
        payload.variantInfo = data.variantInfo
      } else if (payload.mediaType === 'image' && !data.variantInfo) {
        payload.variantInfo = { rendition: 'original' }
      }
    }

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable<UploadRequestParametersV1>(
      functions,
      'createUploadPipelineInSaturn',
    )(payload).catch((error) => console.error(error))

    if (!data.file.url && response?.data?.presignedUrl) {
      response.upload = await axios.put(response.data.presignedUrl, data.file, {
        headers: {
          'Content-Type': data.file.type,
        },
      })
    }

    this.uploading = false

    return response
  }

  public async updateVariants(data: any) {
    this.uploading = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(
      functions,
      'rolloutAndActivateInFilero',
    )(data).catch((error) => console.error(error))

    this.uploading = false

    return response
  }

  public async getJobStatus({ jobId }: GetJobStatusParams) {
    const functions = getFunctions(getApp(), 'europe-west1')

    const response = await httpsCallable<GetJobStatusParams>(functions, 'checkUploadJobStatusInSaturn')({ jobId })

    return response
  }
}
