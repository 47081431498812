<template>
  <ReleaseNotesDialog
    v-model:open="dialogIsOpen"
    v-model:tab="defaultTab"
    :after-close="resetDefaultTab"
    :type="RELEASE_TYPES.CANDIDATE"
  />

  <v-timeline-item :dot-color="stepColor(nextStep, isComplete, AppReleaseStepStates.REVIEW)">
    <h2 class="d-flex flex-start">Submit for review</h2>

    <p class="text-subtitle-2 text-grey-darken-1 font-weight-regular">
      <template v-if="templateSelected">
        Release note template selected. Build can be submitted directly for review.
      </template>

      <template v-else>
        Write release notes in English and request translations. Once translations are complete, build will be submitted
        for review.
      </template>
    </p>

    <v-alert outlined rounded class="mt-2" density="compact" :type="alertType">
      <v-btn
        rounded="1"
        class="mb-2 bg-white"
        color="primary"
        variant="outlined"
        text="Edit release notes"
        @click="dialogIsOpen = true"
      />

      <p v-if="isActive">{{ releaseNotesStatus }}</p>
    </v-alert>

    <v-btn
      v-if="isActive && templateSelected"
      rounded
      class="mt-4"
      color="primary"
      variant="outlined"
      append-icon="mdi-arrow-right"
      :loading="requestInProgress"
      :disabled="!isAppReleaseAdmin"
      :text="`Submit for ${storeName} review`"
      @click="uploadReleaseNotes()"
    />

    <v-btn
      v-if="isActive && releaseNotes?.state === RELEASE_NOTES_STATES.DRAFT"
      rounded
      class="mt-4"
      color="primary"
      variant="outlined"
      text="Request translations"
      append-icon="mdi-arrow-right"
      :loading="requestInProgress"
      :disabled="!isAppReleaseAdmin"
      @click="requestTranslations()"
    />
  </v-timeline-item>
</template>

<script lang="ts">
  import { Component, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { requestReleaseNotesTranslations, stepColor, uploadAppReleaseNotes } from '#views/releases/utilities'

  import { AppStore, ReleasesStore } from '#stores'

  import { AppBuild, ReleaseNoteStates, ReleaseStepStates, ReleaseTypes } from '#types'

  @Component({})
  class StepReleaseNotes extends Vue {
    @Prop({ required: true }) public nextStep!: number

    @Prop({ default: false }) public isComplete!: boolean

    @Prop({ required: true }) public selectedBuild!: AppBuild

    public requestInProgress = false

    public defaultTab = 'android'

    public dialogIsOpen = false

    public readonly stepColor = stepColor

    public readonly RELEASE_TYPES = ReleaseTypes
    public readonly RELEASE_NOTES_STATES = ReleaseNoteStates

    public readonly AppReleaseStepStates = ReleaseStepStates

    private readonly appStore = new AppStore()
    private readonly releasesStore = new ReleasesStore()

    public get isAppReleaseAdmin() {
      return this.appStore.isAppReleaseAdmin
    }

    public get isActive() {
      return this.nextStep === ReleaseStepStates.REVIEW
    }

    public get releaseNotes() {
      return this.releasesStore.releaseNotes[ReleaseTypes.CANDIDATE][this.selectedBuild.platform]
    }

    public get storeName() {
      return this.selectedBuild.platform === 'ios' ? 'App Store' : 'Play Store'
    }

    public get templateSelected() {
      return this.releaseNotes?.state === ReleaseNoteStates.TEMPLATE_TRANSLATION
    }

    public get releaseNotesStatus() {
      if (this.releaseNotes?.state === ReleaseNoteStates.DRAFT) {
        return 'Release notes in draft state. Once ready, request translations.'
      }

      if (this.templateSelected) {
        return `Release note template selected. Upload release notes to ${this.storeName} and submit app for production review.`
      }

      if (this.releaseNotes?.state === ReleaseNoteStates.IN_TRANSLATION) {
        return `Release notes are being translated. Once translated they will be uploaded to the ${this.storeName}.`
      }

      if (this.releaseNotes?.state === ReleaseNoteStates.TRANSLATED) {
        return `Release notes translated and build is ready for review. Please check the release notes in ${this.storeName} before submitting for review.`
      }

      return 'Release notes not yet drafted.'
    }

    public get alertType() {
      return this.releaseNotes?.state === ReleaseNoteStates.TRANSLATED ? 'success' : 'info'
    }

    public uploadReleaseNotes() {
      const { version, platform } = this.selectedBuild

      this.requestInProgress = true
      uploadAppReleaseNotes(this.releaseNotes?.keyId, platform, version).finally(() => {
        this.requestInProgress = false
      })
    }

    @Watch('selectedBuild', { immediate: true })
    public selectedBuildChanged() {
      this.resetDefaultTab()
    }

    public resetDefaultTab() {
      this.defaultTab = this.selectedBuild?.platform || 'android'
    }

    public async requestTranslations() {
      const { platform, version } = this.selectedBuild

      if (!this.releaseNotes || !this.releaseNotes.notes) {
        return
      }

      const confirm = await this.$confirm(
        'Request release note translations?',
        'Translations cannot be edited via Waltari after translation request.',
      )

      if (confirm) {
        this.requestInProgress = true

        const translationJob = await requestReleaseNotesTranslations(
          this.selectedBuild?.platform,
          this.selectedBuild?.version,
          this.releaseNotes.notes,
          this.$dayjs(new Date()).add(7, 'day').toDate(),
        ).catch(() => {
          this.requestInProgress = false
        })

        if (translationJob !== undefined) {
          await this.releasesStore.updateReleaseNotesState(version, platform, {
            jobId: translationJob.id,
            state: ReleaseNoteStates.IN_TRANSLATION,
            ...(this.releaseNotes?.createdAt ? { createdAt: this.releaseNotes.createdAt } : {}),
          })
        }

        this.requestInProgress = false
      }
    }
  }

  export default toNative(StepReleaseNotes)
</script>
