import { Pinia, Store } from 'pinia-class-component'

import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

@Store
export class MediaStore extends Pinia {
  public listing = false
  public fetching = false
  public importing = false
  public previewing = false

  public imageFiles: any[] = []
  public videoFiles: any[] = []
  public audioFiles: any[] = []

  public figmaSlug: string = ''
  public figmaImage: any = null
  public figmaThumb: any = null

  public figmaImages: any[] = []
  public figmaThumbs: any[] = []
  public figmaErrors: any[] = []

  public async getMediaInfo(props: { slug: string; type: string; fetch?: boolean; refresh?: boolean }) {
    this.fetching = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'readMediaFileInfoFromFilero')(props)

    this.fetching = false

    return response
  }
  public async listMediaFiles(props: { types: string[] }) {
    this.listing = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'listMediaObjectsFromFilero', { timeout: 300000 })(props)

    if (props.types.includes('images')) {
      this.imageFiles = response?.data?.images?.items || []
    }

    if (props.types.includes('audios')) {
      this.audioFiles = response?.data?.audios?.items || []
    }

    if (props.types.includes('videos')) {
      this.videoFiles = response?.data?.videos?.items || []
    }

    this.listing = false
  }

  public async importFigmaImages(props: { uploads: any[] }) {
    this.importing = true

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'importAppImagesFromFigma', { timeout: 300000 })(props)

    this.importing = false

    this.figmaImages = []

    return response
  }
  public async previewFigmaImages(props: { slug?: string; files: string[] }) {
    this.previewing = true

    this.figmaSlug = props.slug || ''

    const functions = getFunctions(getApp(), 'europe-west1')

    const response: any = await httpsCallable(functions, 'previewAppImagesFromFigma', { timeout: 300000 })(props)

    this.figmaImages = response?.data?.images || []
    this.figmaThumbs = response?.data?.thumbs || []
    this.figmaErrors = response?.data?.errors || []

    if (props.slug && this.figmaSlug === props.slug) {
      this.figmaImage = response?.data?.images[0] || null
      this.figmaThumb = response?.data?.thumbs[0] || null
    }

    this.previewing = false

    return response
  }
}
