<template>
  <v-row>
    <v-col cols="12">
      <v-sheet>
        <v-data-table
          show-expand
          single-expand
          expand-on-click
          disable-pagination
          hide-default-footer
          no-data-text="No ASSA config OTA rollouts"
          :loading="loading"
          :headers="otaHeaders"
          :expanded="expanded"
          :items="rollouts"
          :items-per-page="100"
          style="width: 100%; max-width: 100%; cursor: pointer"
        >
          <template #item.age="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{
                $dayjs(item.created_at)
                  .fromNow(true)
                  .replace('a day', '1 day')
                  .replace('an hour', '1 hour')
                  .replace('a month', '1 month')
              }}
            </span>
          </template>

          <template #item.value.version="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.value.version }}
            </span>
          </template>

          <template #item.release="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              Released for {{ appPlatforms[item.platform] || 'Android and iOS' }}

              <span v-if="item.min_version && item.max_version">
                versions from {{ item.min_version }} until {{ item.max_version }}
              </span>
              <span v-else-if="item.min_version">versions from {{ item.min_version }} onwards</span>
              <span v-else-if="item.max_version">versions until {{ item.max_version }} release</span>
            </span>
          </template>

          <template #item.creator="{ item }">
            <span :style="!item.flavor || item.flavor === 'release' ? 'font-weight: bold' : ''">
              {{ item.info?.github_workflow || 'No creator info available' }}
            </span>
          </template>

          <template #item.status="{ item }">
            <v-chip
              label
              style="text-transform: capitalize; color: white; min-width: 220px; font-weight: bold"
              :color="rolloutStatusColor(item)"
            >
              {{ (item.flavor || 'production') + ' app' }}
            </v-chip>
          </template>

          <template #expanded-row="{ item: rollout }">
            <td class="expanded-row" :colspan="otaHeaders.length">
              <div class="pl-15 pt-4">
                <div class="d-flex flex-column flex-sm-row mt-4 mb-4 mr-4">
                  <div class="flex-grow-1 ml-3">
                    <v-data-table
                      hide-default-footer
                      :headers="scheduleHeaders"
                      :items="rollout.schedule"
                      :items-per-page="100"
                      class="mt-n6 ml-n4"
                    >
                      <template #item.apply_at="{ item }">
                        <span>{{ new Date((item as any).apply_at).toUTCString() }}</span>
                      </template>
                    </v-data-table>
                  </div>

                  <v-spacer />

                  <div class="pr-4">
                    <div class="caption mb-6" style="text-align: right">CREATED</div>
                    <b>{{ $dayjs(rollout.created_at).format('HH:mm DD MMM YYYY') }}</b>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row mt-8 mb-4 mr-4">
                  <v-spacer />

                  <v-btn
                    v-if="rollout.state === 'active'"
                    color="red"
                    text="Disable rollout"
                    prepend-icon="mdi-block-helper"
                    :disabled="!hasAdminRights"
                    @click="disableRollout(rollout)"
                  />

                  <v-btn
                    v-else
                    color="green"
                    text="Enable rollout"
                    prepend-icon="mdi-check-bold"
                    :disabled="!hasAdminRights"
                    @click="enableRollout(rollout)"
                  />
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { Component, Vue, toNative } from 'vue-facing-decorator'

  import { appPlatforms, otaHeaders, scheduleHeaders } from '#views/rollouts/constants'

  import { AppStore, RolloutsStore } from '#stores'

  @Component({})
  class ConfigRollouts extends Vue {
    public expanded: any[] = []

    public readonly otaHeaders = otaHeaders
    public readonly appPlatforms = appPlatforms
    public readonly scheduleHeaders = scheduleHeaders

    private readonly appStore = new AppStore()
    private readonly rolloutsStore = new RolloutsStore()

    private interval: number | undefined = undefined

    public get loading() {
      return this.rolloutsStore.loading
    }

    public get rollouts() {
      return this.rolloutsStore.rollouts
    }

    public get hasAdminRights() {
      return this.appStore.isOTAConfigAdmin || this.appStore.isWaltariAdmin
    }

    public mounted() {
      this.rolloutsStore.listOtaRollouts('assa_config')

      this.interval = window.setInterval(this.updateRollouts, 5 * 60 * 1000)
    }

    public beforeUnmount() {
      if (this.interval) {
        window.clearInterval(this.interval)

        this.interval = undefined
      }
    }

    public updateRollouts() {
      this.rolloutsStore.listOtaRollouts('assa_config')
    }

    public enableRollout(rollout: any) {
      this.$confirm('Confirm enabling this rollout', 'This will enable serving this configuration to the apps.').then(
        (confirmed) => {
          if (confirmed) {
            this.rolloutsStore.enableOtaRollout('assa_config', rollout.slug)
          }
        },
      )
    }

    public disableRollout(rollout: any) {
      this.$confirm('Confirm disabling this rollout', 'This will disable serving this configuration to the apps.').then(
        (confirmed) => {
          if (confirmed) {
            this.rolloutsStore.disableOtaRollout('assa_config', rollout.slug)
          }
        },
      )
    }

    public rolloutStatusColor(rollout: any) {
      return rollout.state === 'disabled'
        ? 'grey'
        : !rollout.flavor || rollout.flavor === 'release'
          ? 'blue'
          : rollout.flavor === 'debug'
            ? 'green'
            : 'purple'
    }
  }

  export default toNative(ConfigRollouts)
</script>
