<template>
  <div style="position: relative">
    <organization-chart ref="orgChartRef" pan :datasource="chartData" :style="'max-height: ' + (height || 480) + 'px'">
      <template #default="{ nodeData }">
        <template v-if="!!nodeData.title">
          <div class="title" :class="'bg-' + nodeData.color" :innerHTML="nodeData.name" />
          <div v-if="!!nodeData.title" class="content" :innerHTML="nodeData.title" />
        </template>
        <template v-else>
          <div v-if="!!nodeData.color" class="label" :class="'bg-' + nodeData.color" :innerHTML="nodeData.name" />
          <div v-else class="operator" :innerHTML="nodeData.name" />
        </template>
      </template>
    </organization-chart>

    <v-btn
      icon="mdi-plus-thick"
      size="small"
      variant="tonal"
      style="position: absolute; top: 16px; right: 16px; z-index: 10"
      @click="chartScale = Math.min(7, chartScale + 1)"
    />
    <v-btn
      icon="mdi-minus-thick"
      size="small"
      variant="tonal"
      style="position: absolute; top: 64px; right: 16px; z-index: 10"
      @click="chartScale = Math.max(0.5, chartScale - 1)"
    />
  </div>
</template>

<script lang="ts">
  // @ts-expect-error - This libary has no typescript support
  import OrganizationChart from 'vue3-organization-chart'

  import { Component, Prop, Ref, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { criteriaEnumTypes, criteriaOperators, criteriaPredicates } from '#views/segments/constants'

  @Component({
    components: {
      OrganizationChart,
    },
  })
  class CriteriaChart extends Vue {
    @Prop() public height!: number

    @Prop() public criteria!: any
    @Prop() public criterias!: any

    public chartScale = 1

    public chartData: any = {}

    @Ref() public readonly orgChartRef!: any

    @Watch('criteria', { immediate: true, deep: true })
    protected criteriaChanged() {
      if (this.criteria) {
        this.viewCriteriaChart()
      }
    }

    @Watch('chartScale')
    protected chartScaleChanged() {
      if (this.orgChartRef) {
        const matrix = this.orgChartRef.$el.firstChild.style.transform?.split('(')[1]?.split(')')[0]?.split(',') || []

        this.orgChartRef.transformVal = this.orgChartRef.$el.firstChild.style.transform = `matrix(${
          this.chartScale
        }, 0, 0, ${this.chartScale}, ${matrix[4] || 0}, ${matrix[5] || 0})`
      }
    }

    public viewCriteriaChart() {
      if (this.criteria) {
        this.chartData = this.buildCriteriaChart(this.criteria)

        setTimeout(() => {
          if (this.orgChartRef) {
            this.chartScale = Math.max(
              0.5,
              Math.min(2, this.orgChartRef.$el.offsetWidth / this.orgChartRef.$el.firstChild.offsetWidth),
            )

            this.orgChartRef.$el.firstChild.style['transform-origin'] =
              `top ${this.chartScale >= 1 ? 'center' : 'left'}`

            const orgHandler = this.orgChartRef.panHandler

            this.orgChartRef.panHandler = (event: any) => {
              const value = orgHandler(event)

              if (value) {
                const matrix = value.split('(')[1]?.split(')')[0]?.split(',') || []

                this.orgChartRef.transformVal = this.orgChartRef.$el.firstChild.style.transform = `matrix(${
                  this.chartScale
                }, 0, 0, ${this.chartScale}, ${matrix[4] || 0}, ${matrix[5] || 0})`
              }
            }
          }
        }, 100)
      }
    }

    public buildCriteriaChart(
      criteria: any,
      parent?: any,
      op?: string,
      pidx?: number,
      color?: string,
      invert?: boolean,
    ) {
      if (!parent) {
        if (criteria.oneOf?.$case !== 'predicate' && criteria.oneOf[criteria.oneOf.$case].expressions.length === 1) {
          criteria = criteria.oneOf[criteria.oneOf.$case].expressions[0]
        }

        parent = {
          id: 'root',
          name: criteria.oneOf?.predicate || criteria.oneOf?.$case === 'and' ? 'ALL OF' : 'ONE OF',
          children: [],
        }

        if (criteria.oneOf?.predicate) {
          this.buildCriteriaChart({ expressions: [criteria] }, parent)
        } else {
          this.buildCriteriaChart(criteria.oneOf[criteria.oneOf.$case], parent)
        }

        return parent
      } else if (criteria.expressions) {
        const expText = JSON.stringify(criteria.expressions)

        criteria.expressions.forEach((expression: any, index: number) => {
          let p = parent

          // const text = JSON.stringify(expression.oneOf[expression?.oneOf?.$case])

          switch (expression?.oneOf?.$case) {
            case 'and':
              if (
                (expression.oneOf.and.expressions.length > 1 && p.name !== 'ALL OF') ||
                p.children.find((c: any) => c.name === 'ALL OF' || c.name === 'ONE OF')
                /*&&
                (text.includes('"or":') || text.includes('"and":') || text.includes('"not":'))*/
              ) {
                p.children.push({
                  id: p.id + '-and-' + index,
                  name: 'ALL OF',
                  children: [],
                })

                this.buildCriteriaChart(
                  expression.oneOf.and,
                  p.children[p.children.length - 1],
                  'and',
                  index,
                  color,
                  invert,
                )
              } else {
                this.buildCriteriaChart(expression.oneOf.and, p, 'and', index, color, invert)
              }
              break
            case 'not':
              if (expression.oneOf.not.expression) {
                this.buildCriteriaChart(
                  { expressions: [expression.oneOf.not.expression] },
                  p,
                  'and',
                  index,
                  color,
                  !invert,
                )
              }
              break
            case 'or':
              if (
                expression.oneOf.or.expressions.length > 1 /*&&
                (text.includes('"or":') || text.includes('"and":') || text.includes('"not":')) */
              ) {
                p.children.push({
                  id: p.id + '-or-' + index,
                  name: 'ONE OF',
                  children: [],
                })

                this.buildCriteriaChart(
                  expression.oneOf.or,
                  p.children[p.children.length - 1],
                  'or',
                  index,
                  color,
                  invert,
                )
              } else {
                this.buildCriteriaChart(expression.oneOf.or, p, 'or', index, color, invert)
              }
              break
            case 'predicate':
              if (expression.oneOf?.predicate?.oneOf?.generic?.oneOf?.$case === 'namedCriteria') {
                const criteria = this.criterias.find(
                  (c: any) => c.metadata.name === expression.oneOf?.predicate.oneOf.generic.oneOf?.namedCriteria.name,
                )

                color = criteria ? criteria.metadata.informative.labels.color : 'grey'

                p.children.push({
                  id: p.id + '-type-' + pidx + '-' + index,
                  name:
                    (invert ? '<span class="font-weight-bold text-orange">NOT</span> ' : '') +
                    expression.oneOf?.predicate.oneOf.generic.oneOf?.namedCriteria.name.toUpperCase(),
                  color: color,
                  children: [],
                })

                p = p.children[p.children.length - 1]

                this.buildCriteriaChart({ expressions: [criteria.expression] }, p, 'or', index, color, false)
              } else if (this.getPredicateName(expression.oneOf.predicate.oneOf, invert)) {
                p.children.push({
                  id:
                    p.id +
                    '-' +
                    expression.oneOf.$case +
                    '-' +
                    expression.oneOf.predicate.oneOf.$case +
                    '-' +
                    pidx +
                    '-' +
                    index,
                  color: color || 'grey',
                  invert,
                  name: this.getPredicateName(expression.oneOf.predicate.oneOf, invert),
                  title:
                    '<b>' +
                    this.getPredicateOperator(expression.oneOf.predicate.oneOf, invert).toLowerCase() +
                    '</b> ' +
                    this.getPredicateValue(expression.oneOf.predicate.oneOf, invert),
                  children: [],
                })

                if (!expText.includes('"or":') && !expText.includes('"and":')) {
                  if (op && index < criteria.expressions.length - 1) {
                    p = p.children[p.children.length - 1]

                    p.children.push({
                      id:
                        p.id +
                        '-' +
                        expression.oneOf.$case +
                        '-' +
                        expression.oneOf.predicate.oneOf.$case +
                        '-' +
                        op +
                        '-' +
                        pidx +
                        '-' +
                        index,
                      name: op.toUpperCase(),
                      children: [],
                    })
                  }

                  parent = p.children[p.children.length - 1]
                }
              }
              break
          }
        })
      }
    }

    public getPredicateName(predicate: any, inverted?: boolean) {
      let value

      let name = predicate.$case

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf

        name = name + '-' + predicate.$case

        value = criteriaPredicates[inverted ? 'inverted' : 'default'].find((p: any) => p.value === name)?.title

        if (value) {
          return value.replace(/\bnot\b/, '<span class="font-weight-bold text-orange">not</span>')
        }
      }

      return name === 'user-range' ? 'User uuid percentile' : ''
    }

    public getPredicateValue(predicate: any, inverted?: boolean) {
      let type = ''

      let name = predicate.$case

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf

        name = name + '-' + predicate.$case

        type = criteriaPredicates[inverted ? 'inverted' : 'default'].find((p: any) => p.value === name)?.type || ''

        if (type) {
          break
        }
      }

      if (!type && predicate.$case === 'range') {
        return predicate.range.lowerBound.integerPart + '-' + predicate.range.upperBound.integerPart
      } else if (criteriaEnumTypes[type]) {
        return (
          criteriaEnumTypes[type].find((e: any) => e.value === predicate[predicate.$case])?.title ||
          predicate[predicate.$case]
        )
      } else if (type === 'capabilityMatch') {
        const versionOp = criteriaOperators['integerMatch'].find(
          (e: any) => e.value === predicate[predicate.$case].version.oneOf.$case,
        ).title

        return (
          criteriaEnumTypes['ringCapability'].find((e: any) => e.value === predicate[predicate.$case].capability)
            ?.title +
          ` and <b>version</b> ${versionOp.toLowerCase()}` +
          (versionOp !== 'Is present'
            ? ` ${predicate[predicate.$case].version.oneOf[predicate[predicate.$case].version.oneOf.$case]}`
            : '')
        )
      } else if (type === 'timestampMatch' && predicate[predicate.$case].oneOf.$case !== 'isPresent') {
        predicate = predicate[predicate.$case].oneOf

        switch (predicate.$case) {
          case 'after':
            return this.$dayjs(predicate[predicate.$case]).format('DD MMM YYYY')

          case 'before':
            return this.$dayjs(predicate[predicate.$case]).format('DD MMM YYYY')

          case 'withinPast':
            return this.$dayjs.duration(parseInt(predicate[predicate.$case].seconds), 's').asDays() + ' days'

          case 'withinFuture':
            return this.$dayjs.duration(parseInt(predicate[predicate.$case].seconds), 's').asDays() + ' days'
        }
      } else if (type === 'versionMatch' && predicate[predicate.$case].oneOf.$case !== 'isPresent') {
        predicate = predicate[predicate.$case].oneOf

        return (
          predicate[predicate.$case].major +
          '.' +
          predicate[predicate.$case].minor +
          '.' +
          predicate[predicate.$case].patch
        )
      } else {
        if (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf
        }

        return predicate[predicate.$case].strings
          ? predicate[predicate.$case].strings.join(', ')
          : predicate[predicate.$case]
      }
    }

    public getPredicateOperator(predicate: any, inverted?: boolean) {
      let type = ''

      let name = predicate.$case

      while (predicate[predicate?.$case]?.oneOf) {
        predicate = predicate[predicate.$case].oneOf

        name = name + '-' + predicate.$case

        type = criteriaPredicates[inverted ? 'inverted' : 'default'].find((p: any) => p.value === name)?.type

        if (type) {
          break
        }
      }

      if (type === 'capabilityMatch') {
        return 'of'
      } else if (criteriaOperators[type]) {
        while (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf
        }

        return criteriaOperators[type].find((o: any) => o.value === predicate.$case)?.title || ''
      } else {
        return 'equal to'
      }
    }
  }

  export default toNative(CriteriaChart)
</script>

<style lang="scss" scoped>
  :deep(.chartOrgchartContainer) {
    width: 100%;
    height: 60vh;
    overflow-x: hidden;
    font-size: 12px;
    line-height: 12px;

    .chartNode {
      border: none;
      min-height: 24px;
      margin: 0 16px;
      display: inline-block !important;

      &:hover {
        background: transparent;
        box-shadow: none !important;
      }
    }

    table {
      margin: 0;
    }

    .title {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      font-size: 8px !important;
      height: auto;
      padding: 5px 8px;
    }

    .label {
      padding: 6px 8px;
      border-radius: 2px;
      color: white;
      background-color: black;
      font-size: 8px !important;
    }

    .content {
      height: auto;
      padding: 6px 8px;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      font-size: 8px !important;
      border: 1px solid grey;
    }

    .operator {
      display: inline-block;
      position: relative;
      padding: 6px 8px;
      border-radius: 2px;
      color: white;
      background-color: black;
      font-size: 8px !important;
    }

    .chartOrgchart {
      border: none;
      padding: 24px;
    }

    .chartOrgchart .chartLines .chartTopLine {
      border-color: black;
    }

    .chartOrgchart .chartLines .chartRightLine {
      border-color: black;
    }

    .chartOrgchart .chartLines .chartLeftLine {
      border-color: black;
    }

    .chartOrgchart .chartLines .chartDownLine {
      background-color: black;
    }

    .chartOrgchart .verticalNodes > td::before {
      border-color: black;
    }

    .chartOrgchart .verticalNodes ul > li::before,
    .chartOrgchart .verticalNodes ul > li::after {
      border-color: yellow;
    }
  }
</style>
