<template>
  <div v-if="edited" class="d-flex align-baseline">
    <div class="flex-grow-1">
      <v-form ref="metadataFormRef" v-model="validForm" validate-on="lazy">
        <div class="text-button">Metadata</div>

        <v-alert v-if="!edited.metadata?.changeRecord?.createdAt" color="info" class="mt-4 mb-6">
          Please read the feature flags
          <a
            target="_blank"
            class="text-info text-no-wrap text-decoration-underline font-weight-bold"
            href="https://ouraring.atlassian.net/wiki/spaces/SW/pages/4068115084/Feature+Flags+Management"
          >
            &nbsp;documentation
          </a>
          &nbsp;before creating your first feature flag about naming etc.
        </v-alert>

        <v-text-field
          v-model="edited.metadata!.name"
          autofocus
          label="Flag name *"
          class="uppercased mb-2"
          placeholder="Enter name (will be uppercased)"
          :rules="[
            (name: string) =>
              existingFeatures.find(
                (f) =>
                  !edited.metadata?.changeRecord?.createdAt &&
                  f.metadata?.name === name.toLowerCase() &&
                  getProjectKey(f) === getProjectKey(edited),
              )
                ? 'Flag with this name already exists'
                : true,
            (name: string) => (name.match(/^(\d|_)+/g) ? 'Name can\'t start with a number' : true),
          ]"
          :disabled="!!edited.metadata?.changeRecord?.createdAt"
          :hint="
            'Name is permanent and will be prefixed with ' +
            getProjectKey(edited).replace('unknown', '<PROJECT_KEY>') +
            '/'
          "
          @blur="updateName(edited.metadata!.name)"
        />

        <v-textarea v-model="edited.metadata!.informative!.description" label="Description *" rows="3" auto-grow />

        <v-text-field
          v-model="edited.metadata!.informative!.referenceUrls[0]"
          type="url"
          label="More info link *"
          append-icon="mdi-open-in-new"
          :rules="[(v: string) => !v || (v.startsWith('https://') && v.length > 8)]"
          @click:append="openUrl(edited.metadata!.informative!.referenceUrls[0])"
        />

        <v-autocomplete
          label="Project *"
          item-value="id"
          item-title="name"
          :return-object="false"
          :items="validFeatureProjects"
          :disabled="!!original.metadata?.informative?.labels.project"
          :model-value="edited.metadata?.informative?.labels.project || null"
          @update:model-value="
            edited.metadata!.project = createProjectData((edited.metadata!.informative!.labels.project = $event))
          "
        >
          <template #append>
            <v-tooltip
              max-width="390"
              location="top end"
              text="Project controls who can edit the flag and the default rollouts so choose it carefully, see docs for more info"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props">mdi-information-outline</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-autocomplete>

        <v-alert
          v-if="!!edited?.metadata?.informative?.labels?.project && !isProjectEditor(edited)"
          class="mb-6"
          color="warning"
        >
          You do not have rights to the selected project! You need to have the project editor rights to create the flag.
          See the project details for owner / admin contact info and ask one of them to add you to the project editors.
        </v-alert>

        <v-text-field v-model="edited.metadata!.informative!.labels.contact" label="Contact person *" type="email" />

        <div class="text-button mt-2">Settings</div>

        <v-tooltip
          max-width="390"
          open-delay="500"
          location="top center"
          text="Turning this on will make the feature flag have effect only before user login, this can not be changed after creation!"
        >
          <template #activator="{ props }">
            <v-switch
              v-bind="props"
              hide-details
              label="Target unauthenticated users"
              :model-value="
                edited.metadata?.contextSpec?.oneOf &&
                edited.metadata?.contextSpec?.oneOf.$case === 'contexts' &&
                edited.metadata?.contextSpec?.oneOf.contexts.contexts[0] === Metadata_Context.UNAUTHENTICATED_DEVICE
              "
              :disabled="
                !edited.metadata?.contextSpec?.oneOf ||
                !!original.metadata?.changeRecord?.createdAt ||
                edited.metadata?.contextSpec.oneOf.$case !== 'contexts'
              "
              @update:model-value="toggleContext()"
            />
          </template>
        </v-tooltip>

        <div class="text-button my-2">Parameters</div>

        <v-alert v-if="!checkParameters()" text="Parameter names must be unique" color="error" class="mt-4 mb-6" />

        <v-alert
          v-else-if="hasParametersChanged"
          text="Pull request will be automatically created for both app repositories with generated definitions for this flag."
          color="info"
          class="mt-4 mb-6"
        />

        <template v-for="(parameter, i) in edited.parameters" :key="i">
          <FeatureParam
            :index="i"
            :edited-parameter="parameter"
            :original-parameters="original.parameters"
            :created-at="edited.metadata?.changeRecord?.createdAt"
            @delete="deleteParameter($event)"
          />
        </template>

        <v-row id="add-parameter">
          <v-col class="text-right mt-4">
            <v-btn text="Add parameter" color="primary" variant="text" small @click="appendParameter()" />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script lang="ts">
  import slug from 'slug'

  import { cloneDeep, isEqual } from 'lodash-es'

  import { ComponentPublicInstance } from 'vue'

  import { useGoTo } from 'vuetify'
  import { VForm } from 'vuetify/lib/components/VForm/index.mjs'

  import { Component, Emit, Prop, Ref, Setup, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Context } from '@jouzen/feature-mgmt-api/metadata'
  import { Value_Type } from '@jouzen/feature-mgmt-api/parameter'

  import { parameterTypes } from '#views/features/constants'

  import { createProjectData, getProjectKey, isProjectEditor } from '#views/projects/utilities'

  import { FeaturesStore, ProjectsStore } from '#stores'

  import { Feature } from '#types'

  @Component({})
  class FeatureEditor extends Vue {
    @Prop() public edited!: Feature
    @Prop() public original!: Feature

    @Setup(() => useGoTo())
    public goTo!: ReturnType<typeof useGoTo>

    public validForm = true

    public readonly getProjectKey = getProjectKey
    public readonly isProjectEditor = isProjectEditor
    public readonly createProjectData = createProjectData

    public readonly Metadata_Context = Context

    public readonly projectsStore = new ProjectsStore()
    private readonly featuresStore = new FeaturesStore()

    @Ref() public readonly metadataFormRef: ComponentPublicInstance<VForm> | null = null

    public get existingFeatures() {
      return this.featuresStore.features
    }

    public get validFeatureProjects() {
      return this.projectsStore.projects.filter(
        (p) => (p.key && p.sections.features) || p.id === this.edited?.metadata?.informative?.labels?.project,
      )
    }

    public get hasParametersChanged() {
      return !isEqual(this.original.parameters, this.edited.parameters)
    }

    @Emit('validate')
    public validateData() {
      return this.validForm !== false && this.checkParameters() ? this.edited : null
    }

    @Watch('edited', { deep: true })
    protected editedChanged() {
      this.validForm = true

      this.validateData()
    }

    @Watch('validForm')
    protected validFormChanged() {
      this.validateData()
    }

    public openUrl(url: string) {
      if (url) {
        window.open(url, '_blank')
      }
    }

    public updateName(name: string) {
      this.edited.metadata!.name = slug(name.split('/').pop()!, '_').replace(/^(\d|_)+/g, '')
    }

    public toggleContext() {
      if (this.edited.metadata?.contextSpec?.oneOf?.$case === 'contexts') {
        const contextsList =
          this.edited.metadata.contextSpec.oneOf![this.edited.metadata.contextSpec.oneOf!.$case]?.contexts || []

        if (contextsList[0] === Context.UNAUTHENTICATED_DEVICE) {
          this.edited.metadata.contextSpec = {
            oneOf: {
              $case: 'contexts',
              contexts: {
                contexts: [Context.OURA_USER],
              },
            },
          }
        } else {
          this.edited.metadata.contextSpec = {
            oneOf: {
              $case: 'contexts',
              contexts: {
                contexts: [Context.UNAUTHENTICATED_DEVICE],
              },
            },
          }
        }

        this.$forceUpdate()
      }
    }

    public checkParameters() {
      const parameterNames = this.edited.parameters.map((p) => p.name)

      return parameterNames.length === new Set(parameterNames).size
    }

    public appendParameter() {
      this.edited.parameters = [
        ...this.edited.parameters,
        {
          name: '',
          description: '',
          deprecated: false,
          type: cloneDeep(parameterTypes[0].type) as Value_Type,
        },
      ]

      window.setTimeout(() => this.goTo('#add-parameter', { container: '#feature-panel' }), 10)
    }

    public deleteParameter(index: number) {
      this.edited.parameters.splice(index, 1)
    }
  }

  export default toNative(FeatureEditor)
</script>

<style lang="scss" scoped>
  :deep(.uppercased) {
    input {
      text-transform: uppercase;
    }
  }
</style>
