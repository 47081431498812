<template>
  <div class="pt-6">
    <v-table v-if="!criteriaPreview" class="expanded-content">
      <tr>
        <td />
        <td>
          <!-- eslint-disable vue/no-v-html -->
          <div
            style="word-wrap: break-word"
            v-html="`<p>${criteria.metadata?.informative?.description.replace(/\n/gi, '</p><p>')}</p>`"
          />

          <div v-if="criteria.metadata?.informative?.referenceUrls[0]" class="mt-4">
            <a :href="criteria.metadata?.informative?.referenceUrls[0]" class="text-blue" target="_blank">More info</a>
          </div>
        </td>
        <td colspan="2">
          <template v-if="!criteria.expression?.oneOf?.$case">
            <v-row class="justify-space-between mb-1" style="margin: 0">
              <v-col cols="12" class="orange--text" style="padding: 0">No expressions configured</v-col>
            </v-row>
          </template>
          <template
            v-else-if="
              criteria.expression?.oneOf?.$case === 'predicate' &&
              criteria.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
              criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
              criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals'
            "
          >
            <v-row class="justify-space-between mb-1" style="border-bottom: 1px dotted rgba(0, 0, 0, 0.3); margin: 0">
              <v-col cols="3" style="padding: 0">User has label:</v-col>
              <v-col cols="1" class="text-center" style="padding: 0" />
              <v-col class="text-right" style="padding: 0">
                <div class="text-no-wrap">
                  {{ criteria.expression?.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals }}
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-2" style="margin: 0">
              <v-col class="flex-grow-1" style="padding: 0">Users with this label in all envs:</v-col>

              <v-col class="text-right flex-grow-0 pa-0">
                <div v-if="labelLoading">Checking</div>
                <div v-else-if="!labelUsers.length">None</div>
                <div v-else>{{ usersTotal }}</div>
              </v-col>

              <v-col cols="12" class="text-right mt-2 pa-0">
                <div v-for="user in labelUsers.slice(0, 10)" :key="user.userUid" class="text-truncate text-no-wrap">
                  <v-tooltip location="top" :text="cloudEnvs.find((e) => e.value === user.env)?.title || 'Development'">
                    <template #activator="{ props }">
                      <div v-bind="props">{{ user.userUid }}</div>
                    </template>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="usersTotal > 10" class="text-right" style="margin: 0">
              <v-col style="padding: 0">. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .</v-col>
            </v-row>
          </template>
          <template v-else-if="criteria.expression.oneOf.$case === 'or' && criteria.expression.oneOf.or">
            <div v-for="(orExpressions, i) in criteria.expression.oneOf.or.expressions" :key="i">
              <template v-if="orExpressions.oneOf?.$case === 'and'">
                <div v-for="(andExpression, j) in orExpressions.oneOf.and.expressions" :key="j">
                  <template v-if="getPredicateData(andExpression)">
                    <v-row
                      v-if="getPredicateName(andExpression) !== 'unknown'"
                      class="justify-space-between mb-2"
                      style="border-bottom: 1px dotted rgba(0, 0, 0, 0.3); margin: 0"
                    >
                      <v-col cols="4" style="padding: 0">
                        {{ getPredicateName(andExpression) }}
                      </v-col>
                      <v-col cols="4" class="text-center" style="padding: 0">
                        {{ getPredicateOperator(andExpression) }}
                      </v-col>
                      <v-col cols="4" class="text-right" style="padding: 0; word-wrap: break-word">
                        {{ getPredicateValue(andExpression) }}
                      </v-col>
                    </v-row>
                  </template>
                </div>
              </template>

              <div
                v-if="i !== criteria.expression.oneOf.or.expressions.length - 1"
                class="my-8"
                style="position: relative; text-align: center"
              >
                <v-divider />
                <span
                  class="px-4 py-1"
                  style="
                    position: absolute;
                    top: -14px;
                    color: rgb(var(--v-theme-on-surface));
                    background: rgb(var(--v-theme-background));
                    font-weight: bold;
                    transform: translateX(-50%);
                  "
                >
                  OR
                </span>
              </div>
            </div>
          </template>
        </td>
        <td class="pr-8">
          <template v-if="!overrides[criteria.metadata!.name]">No rollouts with this criteria yet</template>
          <template v-else>
            <div v-for="(fo, i) in overrides[criteria.metadata!.name].slice(0, 10)" :key="i">
              <v-row class="justify-space-between mb-1" style="border-bottom: 1px dotted rgba(0, 0, 0, 0.3); margin: 0">
                <v-col cols="9" style="padding: 0; word-wrap: break-word">
                  {{ fo.feature.metadata?.name.toUpperCase() }}/
                  {{ fo.override.metadata?.uid?.split('_').slice(0, -1).join('_').toUpperCase() }}
                </v-col>
                <v-col cols="3" class="text-right" style="padding: 0">
                  <strong
                    v-if="
                      fo.override?.rolloutOneOf?.$case === 'rollout' &&
                      !!fo.override?.rolloutOneOf?.rollout?.stages.find((s: any) => s.state === RolloutState.CURRENT)
                    "
                    class="text-green"
                  >
                    ON
                  </strong>
                  <strong v-else class="ext-red">OFF</strong>
                </v-col>
              </v-row>
            </div>

            <div v-if="overrides[criteria.metadata!.name].length > 10" class="mt-4">
              {{ overrides[criteria.metadata!.name].length - 10 }} more rollouts using this criteria
            </div>
          </template>
        </td>
      </tr>
    </v-table>

    <div v-else>
      <CriteriaChart class="mx-6" height="350" :criteria="criteriaPreview.expression" :criterias="criterias" />
    </div>

    <div class="d-flex flex-column flex-sm-row my-4 mr-4">
      <v-btn
        v-if="!criteriaPreview"
        class="ml-12"
        color="primary"
        text="Show logic tree"
        prepend-icon="mdi-family-tree"
        @click="criteriaPreview = criteria"
      />

      <v-btn
        v-else
        class="ml-12"
        color="primary"
        text="Show information"
        prepend-icon="mdi-information-outline"
        @click="criteriaPreview = null"
      />

      <v-spacer />

      <v-btn
        color="primary"
        class="mr-2"
        prepend-icon="mdi-comment-outline"
        :text="(notesCount ? notesCount + ' ' : '') + (notesCount === 1 ? 'Note' : 'Notes')"
        @click="emitNotes()"
      />

      <v-tooltip top :disabled="isProjectEditor(criteria)">
        <template #activator="{ props }">
          <v-btn class="mr-2" color="primary" :disabled="!isProjectEditor(criteria)" v-bind="props" @click="emitEdit()">
            <v-icon class="mr-2">mdi-pencil-outline</v-icon>
            Modify
          </v-btn>
        </template>
        <div>Request access from the project owner</div>
        {{ (ownerProject && ownerProject.owner) || '' }}
      </v-tooltip>

      <v-tooltip top :disabled="isProjectEditor(criteria)">
        <template #activator="{ props }">
          <v-btn
            color="red"
            :disabled="!!overrides[criteria.metadata!.name] || !isProjectEditor(criteria)"
            v-bind="props"
            @click="deleteCriteria(criteria)"
          >
            <v-icon class="mr-2">mdi-trash-can-outline</v-icon>

            Delete
          </v-btn>
        </template>
        <div>Request access from the project owner</div>
        {{ (ownerProject && ownerProject.owner) || '' }}
      </v-tooltip>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Emit, Prop, Vue, Watch, toNative } from 'vue-facing-decorator'

  import { Unsubscribe, collection, getFirestore, onSnapshot, query } from 'firebase/firestore'

  import { Debounce } from '@jouzen/outo-toolkit-vuetify'

  import { Expression } from '@jouzen/feature-mgmt-api/criteria'
  import { Rollout_Stage_StageState } from '@jouzen/feature-mgmt-api/rollout'

  import { cloudEnvs, criteriaEnumTypes, criteriaOperators, criteriaPredicates } from '#views/segments/constants'

  import { isProjectEditor } from '#views/projects/utilities'
  import { getPredicateData } from '#views/segments/utilities'

  import { AppStore, LabelsStore, ProjectsStore, SegmentsStore } from '#stores'

  import { Criteria, CriteriaOverrides, LabelUser } from '#types'

  @Component({})
  class CriteriaDrawer extends Vue {
    @Prop() public criteria!: Criteria

    @Prop() public criterias!: Criteria[]

    @Prop() public overrides!: CriteriaOverrides

    public notesCount = 0
    public usersTotal = 0

    public labelUsers: LabelUser[] = []

    public criteriaPreview: Criteria | null = null

    public readonly cloudEnvs = cloudEnvs

    public readonly RolloutState = Rollout_Stage_StageState

    public readonly isProjectEditor = isProjectEditor

    public readonly getPredicateData = getPredicateData

    protected readonly appStore = new AppStore()
    protected readonly labelsStore = new LabelsStore()
    protected readonly projectsStore = new ProjectsStore()
    protected readonly segmentsStore = new SegmentsStore()

    protected commentsUnsubscribe: Unsubscribe | null = null

    public get userEmail() {
      return this.appStore.user?.email
    }

    public get labelLoading() {
      return this.labelsStore.waiting
    }

    public get ownerProject() {
      return this.projectsStore.projects.find((p) => p.id === this.criteria.metadata?.informative?.labels.project)
    }

    public get storeLabelUsers() {
      return this.labelsStore.users
    }

    @Emit('edit')
    public emitEdit() {
      return this.criteria
    }

    @Emit('notes')
    public emitNotes() {
      return this.criteria
    }

    @Watch('criteria', { immediate: true })
    protected async criteriaChanged(val?: any, oldVal?: any) {
      this.labelUsers = []

      if (val?.metadata.name !== oldVal?.metadata.name) {
        if (this.commentsUnsubscribe) {
          this.commentsUnsubscribe()
        }

        this.commentsUnsubscribe = onSnapshot(
          query(collection(getFirestore(), `/segments/${this.criteria.metadata?.name}/notes`)),
          (snap) => {
            this.notesCount = snap.docs.length
          },
        )
      }

      if (this.criteria?.metadata?.name === this.criteriaPreview?.metadata?.name) {
        this.criteriaPreview = this.criteria
      }

      if (
        this.criteria.expression?.oneOf?.$case === 'predicate' &&
        this.criteria.expression?.oneOf?.predicate?.oneOf?.$case === 'user' &&
        this.criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.$case === 'hasLabelThat' &&
        this.criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.$case === 'equals' &&
        this.criteria.expression?.oneOf?.predicate?.oneOf?.user?.oneOf?.hasLabelThat?.oneOf?.equals?.length > 0
      ) {
        this.updateLabelUsersPreview(
          this.criteria.expression.oneOf.predicate.oneOf.user.oneOf.hasLabelThat.oneOf.equals,
        )
      }
    }

    @Watch('storeLabelUsers', { immediate: true })
    protected async storeLabelUsersChanged() {
      this.criteriaChanged()
    }

    public beforeUnmount() {
      if (this.commentsUnsubscribe) {
        this.commentsUnsubscribe()

        this.commentsUnsubscribe = null
      }
    }

    public deleteCriteria(criteria: Criteria) {
      this.$confirm('Confirm delete segment?', criteria.metadata?.name?.toUpperCase(), {
        buttonTrueColor: 'error',
      }).then((confirmed) => {
        if (confirmed) {
          this.segmentsStore.deleteCriteria(criteria)

          this.$router.push('/segments')

          // close the nav drawer
          this.appStore.closeNavDrawer()
        }
      })
    }

    public getPredicateName(expression: Expression) {
      const inverted = expression.oneOf?.$case === 'not'

      let predicate: any = getPredicateData(expression)

      if (predicate) {
        let name = predicate.$case

        while (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf

          name = name + '-' + predicate.$case

          const text = criteriaPredicates[inverted ? 'inverted' : 'default'].find((p: any) => p.value === name)?.title

          if (text) {
            return text
          }
        }
      }
    }

    public getPredicateValue(expression: Expression) {
      const inverted = expression.oneOf?.$case === 'not'

      let predicate: any = getPredicateData(expression)

      if (predicate) {
        let type = ''

        let name = predicate.$case

        while (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf

          name = name + '-' + predicate.$case

          type = criteriaPredicates[inverted ? 'inverted' : 'default'].find((p: any) => p.value === name)?.type || ''

          if (type) {
            break
          }
        }

        if (criteriaEnumTypes[type]) {
          return (
            criteriaEnumTypes[type].find((e: any) => e.value === predicate[predicate.$case])?.title ||
            predicate[predicate.$case]
          )
        } else if (type === 'versionMatch') {
          predicate = predicate[predicate.$case].oneOf

          return (
            predicate[predicate.$case].major +
            '.' +
            predicate[predicate.$case].minor +
            '.' +
            predicate[predicate.$case].patch
          )
        } else if (type === 'timestampMatch') {
          predicate = predicate[predicate.$case].oneOf

          if (predicate.$case === 'isPresent') {
            return 'true'
          } else if (predicate.$case === 'before' || predicate.$case === 'after') {
            return this.$dayjs(predicate[predicate.$case]).format('DD MMM YYYY')
          } else {
            return this.$dayjs.duration({ seconds: predicate[predicate.$case].seconds }).asDays() + ' days'
          }
        } else {
          if (predicate[predicate?.$case]?.oneOf) {
            predicate = predicate[predicate.$case].oneOf
          }

          return predicate[predicate.$case].strings
            ? predicate[predicate.$case].strings.join(', ')
            : predicate[predicate.$case]
        }
      }
    }

    public getPredicateOperator(expression: Expression) {
      const inverted = expression.oneOf?.$case === 'not'

      let predicate: any = getPredicateData(expression)

      const predicates: any[] = criteriaPredicates[inverted ? 'inverted' : 'default']

      if (predicate) {
        let type = ''

        let name = predicate.$case

        while (predicate[predicate?.$case]?.oneOf) {
          predicate = predicate[predicate.$case].oneOf

          name = name + '-' + predicate.$case

          type = predicates.find((p) => p.value === name)?.type

          if (type) {
            break
          }
        }

        if (type === 'capabilityMatch') {
          return 'of'
        } else if (criteriaOperators[type]) {
          while (predicate[predicate?.$case]?.oneOf) {
            predicate = predicate[predicate.$case].oneOf
          }

          return (criteriaOperators[type].find((o: any) => o.value === predicate.$case)?.title || '').toLowerCase()
        } else {
          return 'equal to'
        }
      }
    }

    @Debounce(500)
    private async updateLabelUsersPreview(label: string) {
      const data = await this.labelsStore.fetchLabelUsers(label)

      this.labelUsers = data?.users || []
      this.usersTotal = data?.pagination.total || 0
    }
  }

  export default toNative(CriteriaDrawer)
</script>
